import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    const body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // email 발송양식 리스트 가져오기
  getEmailFormList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'email',
        version: '1.0',
        action: 'getEmailFormList'
      }, search
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // email 발송양식 상세 가져오기
  getEmailFormDetail( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'email',
        version: '1.0',
        action: 'getEmailFormDetail'
      }, search
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // email 발송양식 저장
  setEmailForm( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'email',
        version: '1.0',
        action: 'setEmailForm'
      }, form.value
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // email 발송양식 삭제
  delEmailForm( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'email',
        version: '1.0',
        action: 'delEmailForm'
      }, form.value
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // email 발송내역
  getEmailSendList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'email',
        version: '1.0',
        action: 'getEmailSendList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // email 발송통계
  getEmailStatistic( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'email',
        version: '1.0',
        action: 'getEmailStatistic'
      }, search
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // email 발송
  setSendEmail( params: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'email',
        version: '1.0',
        action: 'setSendEmail'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

}
