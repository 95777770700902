/*******************************************************************************
  설  명 : 주문관리 - 주문 상세정보
  작성일 : 2020-12-12
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbDateStruct, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as $ from 'jquery';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';
import { CommonService } from '@app/service/common.service';
import { BasicService } from '@app/service/basic.service';
import { SiteConfigService } from '@app/service/site.config.service';
import { ProductService } from '@app/service/product.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { ModalSmsComponent } from '@components/modal-sms/modal-sms.component';

import { AgGridButtonComponent } from '@components/ag-grid-button/ag-grid-button.component';
import { MemberFindComponent } from '@components/member-find/member-find.component';
import { ProductFindPropertyComponent } from '@components/product-find-property/product-find-property.component';

import { CustomerTabInfoComponent } from '@page/basic/customer/customer-tab-info/customer-tab-info.component';
import { OrderStatusChangeComponent } from '@page/order/order-detail/order-status-change/order-status-change.component';
import { OrderPaymentAddComponent } from '@page/order/order-detail/order-payment-add/order-payment-add.component';
import { OrderStatementComponent } from '@page/order/order-detail/order-statement/order-statement.component';
import { ProductSenaProcessComponent } from '@page/product/product-sena/product-sena-process/product-sena-process.component';
import { OrderDeliveryChargeAddComponent } from '@page/order/order-detail/order-delivery-charge-add/order-delivery-charge-add.component';
import { OrderAmtEditComponent } from '@page/order/order-detail/order-amt-edit/order-amt-edit.component';

const CONFIG_KEY = 'DELIVERY';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @Input() set memNo(value: any) {
    this.memberNo = value;
  }

  public is_popup_preview: boolean = false;

  public baseURL = config.baseUrl;

  public params: any = {
    pageNo: 1,
    pageRow: 20,
    seq: '',
    iniseq: '',
    master_seq: '',
    searchField: '',
    searchText: '',
    searchField2: '',
    searchText2: '',
    shop_gbn: '',
    sdate: '',
    edate: '',
    paydate: '',
    order_status: '',
    searchTerm: 'A',
    searchType: '',
    topSearchText: '',
    suspense: '',
    totalCount: '',
  };

  public activeTab: any = 0;

  public memberInfo: any = {};
  public orderInfo: any = {};
  public paySum: any = {
    mileageSum: 0,
    otherSum: 0,
    cancelSum: 0
  };

  public bankList: any = [];

  public salerList: any = [];
  public shopList: any = [];
  public memoList: any = [];
  public sendList: any = [];
  public customerList: any = [];
  public mallTypeList: any = [
    { value: 'BAMA', title: 'BAMA 쇼핑몰' },
    { value: 'HAPPYCALL', title: '해피콜 결과' },
  ];

  public detailTotalAmt: number = 0;

  public form: FormGroup;
  public formErrors = {};

  public memoForm: FormGroup;
  public memoFormErrors = {};

  private dateModel: NgbDateStruct;
  private date: NgbDateStruct = this.utilService.getDate('');

  public summernoteConfig: any = config.summernoteConfig;

  public daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12'],
    // type: 'inline',
    // target: 'daumAddressApiWrap'
  };

  public setOrderMemoSaveFunc: any;
  public addMemberFunc: any;            // 회원검색
  public changeMemberFunc: any;           // 비회원에서 회원으로 전환
  public setDeliveryFreeFunc: any;            // 무료배송처리
  public setDeliveryChargeFunc: any;       // 배송비부과

  public components: any;

  public memberNo: any;
  public dormant: any;

  public consign_infoSelect: any;
  public send_bankSelect: any;

  public delivery = {
    delivery_amt: '',
    delivery_overcharge: '',
    delivery_free_condition: '',
  }

  // 그리드 관련 선언
  orderGridApi: any;
  orderGridColumnApi: any;
  orderColumnDefs: any;

  orderSumGridApi: any;
  orderSumGridColumnApi: any;
  orderSumColumnDefs: any;

  payGridApi: any;
  payGridColumnApi: any;
  payColumnDefs: any;

  deliveryGridApi: any;
  deliveryGridColumnApi: any;
  deliveryColumnDefs: any;

  memoGridApi: any;
  memoGridColumnApi: any;
  memoColumnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;
  rowClassRules: any;
  rowHeight: any;

  isRowSelectable: any;
  isRowSelectablePay: any;
  isRowSelectableDelivery: any;
  rowClassRulesMemo: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [] ],
      master_seq: ['', [] ],
      order_status: ['0', [Validators.required] ],
      order_status_name: ['', [] ],
      order_date: [null, [Validators.required] ],
      mem_no: ['', [] ],
      o_name: ['', [Validators.required] ],
      id: ['', [] ],
      level: ['', [] ],
      grade: ['', [] ],
      grade_name: ['', [] ],
      o_email: ['', [] ],
      o_hp: ['', [Validators.required] ],
      o_hp2: ['', [] ],
      o_zipcode: ['', [] ],
      o_address: ['', [] ],
      o_address_detail: ['', [] ],
      account_seq: ['', [Validators.required] ],
      bank: ['', [] ],
      receipt_name: ['', [] ],
      saler: ['', [] ],
      shop_gbn: ['0001', [] ],
      send_letter: ['', [] ],
      r_name: ['', [Validators.required] ],
      r_hp: ['', [Validators.required] ],
      r_hp2: ['', [] ],
      unde_flag: ['', [] ],
      r_zipcode: ['', [Validators.required] ],
      r_address: ['', [Validators.required] ],
      r_address_detail: ['', [] ],
      r_comment: ['', [] ],
      detail: [[], [Validators.required] ],
      detail_json: ['', [] ],
      sum: [[], [] ],
      pay: [[], [] ],
      delivery: [[], [] ],
      openmarket_yn: ['0', []],
      onoff_gbn: ['ON', [] ],
      member_memo_cnt: ['0', [] ],
      return_bank: ['', []],
      return_account: ['', []],
      return_dipositor: ['', []],
      delivery_price: ['', [] ],
      org_delivery_price: ['', [] ],
      delivery_amt: ['', [] ],
      delivery_overcharge: ['', [] ],
      delivery_free_condition: ['', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildMemoForm(): void {
    this.memoForm = this.formBuilder.group({
      orderSeq: ['', [Validators.required] ],
      customer_seq: ['0', [] ],
      malltype: ['BAMA', [] ],
      comment: ['', [Validators.required] ]
    });

    this.memoForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.memoForm, this.memoFormErrors );
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private commonService: CommonService,
    private basicService: BasicService,
    private orderService: OrderService,
    private agGridExComponent: AgGridExComponent,
    private siteConfigService: SiteConfigService,
    private productService: ProductService
  ) {
    this.buildForm();
    this.buildMemoForm();

    this.setOrderMemoSaveFunc = this.setOrderMemoSave.bind(this);
    this.addMemberFunc = this.searchMember.bind(this);
    this.changeMemberFunc = this.changeMember.bind(this);
    this.setDeliveryFreeFunc = this.setDeliveryFree.bind(this);
    this.setDeliveryChargeFunc = this.setDeliveryCharge.bind(this);

     // ag grid 컬럼 선언
     this.orderColumnDefs = [
      { headerName: '', field: 'selection', width: 45, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '상품번호', field: 'product_seq', width: 82, cellClass: 'center',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'order_product_seq',
          btnClicked: this.productSeqButton.bind(this)
        }
      },
      { headerName: '상품자식번호', field: 'product_property_seq', hide: true },
      // { headerName: '주문번호', field: 'order_seq', width: 80, cellClass: 'center ag-cell80h',  },
      // { headerName: '주문일자', field: 'order_date', width: 160, cellClass: 'center ag-cell80h',  },
      { headerName: '제조사', field: 'brand_name', width: 110, cellClass: 'center cell-wrap-text',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'order_brand',
          providerClicked: this.openCustomerInfo.bind(this),
          senaClicked: this.openSenaProcess.bind(this)
        }
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 100, cellClass: 'cp center ag-cell80h', cellRenderer: 'agGridImageComponent' },
      { headerName: '상품명', field: 'product_name', width: 243, cellClass: 'left cell-wrap-text',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'order_product_info',
          deliveryClicked: this.deliveryButton.bind(this)
        }
      },
      { headerName: '이벤트', field: 'event_name', width: 60, cellClass: 'center cell-wrap-text' },
      { headerName: '수량', field: 'qty', width: 55,
        cellClass: function(params) {
          if ( !params.data.order_status || params.data.order_status == '0' ) return 'right ag-cell80h ag-cell-edit';
          else return 'right ag-cell80h';
        },
        editable: function(params) {
          if ( !params.data.order_status || params.data.order_status == '0' ) return true;
          else return false;
        },
        valueFormatter: this.agGridExComponent.currencyFormatter,

      },
      { headerName: '취소후수량', field: 'refundQty', width: 85, cellClass: 'right fb ag-cell80h', cellStyle: {'background-color': '#d1eff9'},
        cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let refundQty = parseInt(params.data.refundQty) || 0;
          let qty = parseInt(params.data.qty);
          
          if ( params.data.total_amt < 0 ) {
            return '';
          } else {
            return qty + refundQty;
          }
        }
      },
      { headerName: '구매단가', field: 'buy_price', width: 80,
        cellClass: function(params) {
          if ( !params.data.order_status || params.data.order_status == '0' ) return 'cp right ag-cell80h ag-cell-edit';
          else return 'right ag-cell80h';
        },
        editable: function(params) {
          if ( !params.data.order_status || params.data.order_status == '0' ) return true;
          else return false;
        },
        valueFormatter: this.agGridExComponent.currencyFormatter,
      },
      { headerName: '할인', field: 'discount_amt', width: 80,
        cellClass: function(params) {
          if ( !params.data.order_status || params.data.order_status == '0' ) return 'cp right ag-cell80h ag-cell-edit';
          else return 'right ag-cell80h';
        },
        editable: function(params) {
          if ( !params.data.order_status || params.data.order_status == '0' ) return true;
          else return false;
        },
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '판매단가', field: 'unit_price', width: 80, cellRenderer: 'agGridHtmlComponent',
        cellClass: function(params) {
          if ( params.data.unit_price != params.data.amt || params.data.discount_amt > 0 ) return 'right ag-cell80h-br';
          else return 'right ag-cell80h';
        },
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          let unit_price = getComma(params.data.unit_price);
          let amt = getComma(params.data.amt);
          let calc_amt = parseInt(params.data.amt) - parseInt(params.data.discount_amt);

          return ( unit_price != amt ? '<span style="color:#898989;">' + unit_price + '</span><br/>' : '' ) + amt + ( params.data.discount_amt > 0 ? '<br/><span class="cred">' + getComma(calc_amt) + '</span>' : '' );
        }
      },
      { headerName: '마일리지', field: 'mileage', width: 80,
        valueFormatter: this.agGridExComponent.currencyFormatter,
        cellClass: function(params) {
          if ( !params.data.order_status || params.data.order_status == '0' ) {
            if( parseInt(params.data.event_seq) > 0 && params.data.event_mileage == '0' ) return 'right ag-cell80h';
            else return 'cp right ag-cell80h ag-cell-edit';
          } else return 'right ag-cell80h';
        },
        editable: function(params) {
          if ( !params.data.order_status || params.data.order_status == '0' ) {
            if( parseInt(params.data.event_seq) > 0 && params.data.event_mileage == '0' ) return false;
            else return true;
          } else return false;
        },
      },
      { headerName: '소계', field: 'total_amt', width: 90, cellClass: 'right ag-cell80h',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '취소후소계', field: '', width: 90, cellClass: 'right ag-cell80h', cellStyle: {'background-color': '#d1eff9'},
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          let refundQty = parseInt(params.data.refundQty) || 0;
          let qty = parseInt(params.data.qty);
          let amt = parseInt(params.data.amt);
          let discount_amt = parseInt(params.data.discount_amt);

          let calc_amt = ( qty + refundQty ) * ( amt - discount_amt );

          return ( calc_amt > 0 ) ? calc_amt : '';
        }
      },
      { headerName: '출고지시', field: 'status', width: 85, cellClass: 'center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let statusArr = ( params.data.status !== null ) ? params.data.status.split(',') : '';
          let refundQty = parseInt(params.data.refundQty) || 0;
          let qty = parseInt(params.data.qty);
          let outOrderQty = parseInt(params.data.out_order_qty);
          let refundOrderStatusName: any = ( params.data.refund_order_status_name !== null ) ? params.data.refund_order_status_name : '';

          let status = '';
          if( qty + refundQty > 0 ) {
            if( statusArr !== '' ) {
              statusArr.forEach((value,index) => {
                if( index > 0 ) status = status + '<br/>';
                if ( value == '1000' ) {
                  status = status + '<span class="badge badge-danger f12 mt5">출고지시</span>';
                } else if ( value == '2000' ) {
                  status = status + '<span class="badge badge-primary f12 mt5">출고완료</span>';
                } else {
                  status = status + '<span class="badge badge-secondary f12 mt5">문의</span>';
                }
              });
            }

            status = status + '<br/>' + ( qty + refundQty ) + ' / ' + outOrderQty;

          } else {
            status = refundOrderStatusName.replace('전체','').replace('일부','').replace('주문','').replace('출고전','');
          }

          return status;
        }
      },
      { headerName: '출고/미출고', field: '', width: 90, cellClass: 'right ag-cell80h', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let out_qty: number = ( params.data.out_qty !== null && params.data.out_qty !== undefined ) ? params.data.out_qty : 0;
          let incompleteOutQty: number = ( params.data.incompleteOutQty !== null && params.data.incompleteOutQty !== undefined ) ? params.data.incompleteOutQty : 0;

          if( incompleteOutQty < 0 ) incompleteOutQty = 0;

          return out_qty + ' / ' + incompleteOutQty;
        }
      },
      { headerName: '품절상태', field: 'solodOut', width: 90, cellClass: 'center ag-cell80h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let solodOut: any = params.data.solodOut;
          let daeguStockQty: any = ( params.data.daegu_stock_qty !== null ) ? params.data.daegu_stock_qty : 0;
          let seoulStockQty: any = ( params.data.seoul_stock_qty !== null ) ? params.data.seoul_stock_qty : 0;

          if ( params.data.solodOut == '0' ) {
            solodOut = '<span class="badge badge-success f12">판매중</span>';
          } else if ( params.data.solodOut == '1' ) {
            solodOut = '<span class="badge badge-warning f12">일시품절</span>';
          } else if ( params.data.solodOut == '2' ) {
            solodOut = '<span class="badge badge-danger f12">영구품절</span>';
          } else {
            solodOut = '';
          }

          return `${solodOut}<br/>대구 : ${daeguStockQty}<br/>서울 : ${seoulStockQty}`;
        }
      },
      { headerName: '상태', field: '', width: 100, cellRenderer: 'agGridHtmlComponent',
        cellClass: 'center ag-cell80h-br',
        valueGetter(params: any) {
          let returnStatus: any = ( params.data.return_status !== null ) ? params.data.return_status : '';
          let returnDate: any = ( params.data.return_date !== null ) ? moment(params.data.return_date).format('YY-MM-DD') : '';
          let exchangeDate: any = ( params.data.exchange_date !== null ) ? moment(params.data.exchange_date).format('YY-MM-DD') : '';
          let refundQty = parseInt(params.data.refundQty) || 0;
          let qty = parseInt(params.data.qty);
          let orderDate = moment(params.data.order_date).format('YY-MM-DD');
          let outOrderDateArr = ( params.data.out_order_date ) ? params.data.out_order_date.split(',') : '';
          let outDateArr = ( params.data.out_date ) ? params.data.out_date.split(',') : '';

          let outOrderDate = '';
          if( outOrderDateArr !== '' ) {
            outOrderDateArr.forEach((value,index) => {
              if( index > 0 ) outOrderDate = outOrderDate + '<br/>';
              outOrderDate = outOrderDate + moment(value).format('YY-MM-DD');
            });
          }

          let outDate = '';
          if( outDateArr !== '' ) {
            outDateArr.forEach((value,index) => {
              if( index > 0 ) outDate = outDate + '<br/>';
              outDate = outDate + moment(value).format('YY-MM-DD');
            });
          }

          let status = '';
          if( qty > 0 && (refundQty + qty) < 1 ) {
            status = '';
          } else {
            if ( params.data.order_status == '0' ) {
              status = '<span class="badge badge-secondary f12">' + ( params.data.order_status_name ? params.data.order_status_name : '추가' ) + '</span>';
              status += '<br/>' + orderDate;
            } else if ( params.data.order_status == '1' ) {
              status = '<span class="badge badge-dark f12">' + params.data.order_status_name + '</span>';
              status += '<br/>' + orderDate;
            } else if ( params.data.order_status == '2' ) {
              status = '<span class="badge badge-success f12">' + params.data.order_status_name + '</span>';
              status += '<br/>' + outOrderDate;
            } else if ( params.data.order_status == '3' ) {
              status = '<span class="badge badge-danger f12">' + params.data.order_status_name + '</span>' + '<br/>' + '<span class="f12" style="color: blue">' + returnStatus + '</span>';
              status += '<br/>' + ( returnDate !== '' ? returnDate : orderDate );
            } else if ( params.data.order_status == '4' ) {
              status = '<span class="badge badge-warning f12">' + params.data.order_status_name + '</span>' + '<br/>' + '<span class="f12" style="color: blue">' + returnStatus + '</span>';
              status += '<br/>' + ( returnDate !== '' ? returnDate : orderDate );
            } else if ( params.data.order_status == '5' ) {
              status = '<span class="badge badge-danger f12">' + params.data.order_status_name + '</span>' + '<br/>' + '<span class="f12" style="color: blue">' + returnStatus + '</span>';
              status += '<br/>' + ( exchangeDate !== '' ? exchangeDate : orderDate );
            } else if ( params.data.order_status == '6' ) {
              status = '<span class="badge badge-warning f12">' + params.data.order_status_name + '</span>' + '<br/>' + '<span class="f12" style="color: blue">' + returnStatus + '</span>';
              status += '<br/>' + ( exchangeDate !== '' ? exchangeDate : orderDate );
            } else if ( params.data.order_status == '7' ) {
              status = '<span class="badge badge-danger f12">' + params.data.order_status_name + '</span>';
              status += '<br/>' + orderDate;
            } else if ( params.data.order_status == '8' ) {
              status = '<span class="badge badge-primary f12">' + params.data.order_status_name + '</span>';
              status += '<br/>' + outDate;
            } else if ( params.data.order_status == '9' ) {
              status = '<span class="badge badge-info f12">' + params.data.order_status_name + '</span>';
              status += '<br/>' + outDate;
            } else if ( params.data.order_status == '10' ) {
              status = '<span class="badge badge-danger f12">' + params.data.order_status_name + '</span>'  + '<br/>' + '<span class="f12" style="color: blue">' + returnStatus + '</span>';
              status += '<br/>' + orderDate;
            } else {
              status = '';
            }
          }

          return ( status != '' ) ? status : '';
        }
      },
    ];

     // ag grid 컬럼 선언
     this.orderSumColumnDefs = [
      { headerName: '', field: '', width: 920, cellClass: 'cp center' },
      { headerName: '수량합계', field: 'sum_qty', width: 120, cellClass: 'cp center', hide: true },
      { headerName: '판매합계', field: 'sum_amt', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '할인합계', field: 'sum_discount_amt', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '구매합계', field: 'sum_buy_price', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '마일리지합계', field: 'sum_mileage', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '합계', field: 'sum_total_amt', width: 100, cellClass: 'cp right',
        valueFormatter: this.agGridExComponent.currencyFormatter
      },
      { headerName: '', field: '', width: 100, cellClass: 'cp center' },
      { headerName: '', field: '', width: 100, cellClass: 'cp center' }
    ];

     // ag grid 컬럼 선언
     this.payColumnDefs = [
      { headerName: '', field: 'seq', hide: true },
      { headerName: '', field: '', width: 50, cellClass: 'cp center',
     //   headerCheckboxSelection: false, headerCheckboxSelectionFilteredOnly: true,  
        checkboxSelection(params: any) {
          if( params.data.app_gbn == '1' ) return true;
          else return false;
      }
      },
      { headerName: '결제수단', field: 'pay_method_name', width: 100, cellClass: 'cp center' },
      { headerName: '결제일자', field: 'pay_date', width: 145, cellClass: 'cp' },
      { headerName: '결제금액', field: 'pay_amt', width: 100, cellClass: 'cp right', cellRenderer: 'agGridHtmlComponent',
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          if ( params.data.pay_amt < 0 ) {
            return '<span class="cred">' + getComma(params.data.pay_amt) + '</span>';
          } else {
            return getComma(params.data.pay_amt);
          }
        }
      },
      { headerName: '등록자', field: 'writer_name', width: 80, cellClass: 'cp center' },
      { headerName: 'PG그룹사', field: 'pg_group_name', width: 120, cellClass: 'cp center' },
      { headerName: '승인번호', field: 'app_no', width: 100, cellClass: 'cp center' },
      { headerName: '승인일시', field: '', width: 150, cellClass: 'cp center',
        valueGetter(params: any) {
          if ( params.data.app_date == null && params.data.app_time == null ) {
            return ''
          } else {
            return params.data.app_date + ' ' + params.data.app_time;
          }
        }
      },
      { headerName: '주문번호', field: 'oid', width: 200, cellClass: 'cp center', editable: true, singleClickEdit: true, },
      { headerName: 'TID', field: 'tid', width: 330, cellClass: 'cp center', editable: true, singleClickEdit: true, },
      { headerName: '카드종류', field: 'card_name1', width: 80, cellClass: 'cp center' },
      { headerName: '할부기간', field: 'installment_period', width: 80, cellClass: 'cp center' }
    ];

     // ag grid 컬럼 선언
     this.deliveryColumnDefs = [
      { headerName: '', field: 'seq', hide: true },
      { headerName: '', field: '', width: 50, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      { headerName: '매입처', field: 'customer_name', width: 200, cellClass: 'cp left lh50' },
      { headerName: '배송료', field: 'delivery_charge', width: 80, cellClass: 'cp right lh50', 
        cellRenderer: 'agGridHtmlComponent',
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          if ( params.data.delivery_charge < 0 ) {
            return '<span class="cred">' + getComma(params.data.delivery_charge) + '</span>';
          } else {
            return getComma(params.data.delivery_charge);
          }
        }
      },
      { headerName: '등록정보', field: 'writer_name', width: 150, cellClass: 'cp left cell-wrap-text',
        cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let writer_name = params.data.writer_name || '';
          let write_date = params.data.write_date || '';

          return `${writer_name}<br/>${write_date}`;
        }
      },
      { headerName: '수정정보', field: 'change_date', width: 150, cellClass: 'cp left cell-wrap-text',
        cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let changer_name = params.data.changer_name || '';
          let change_date = params.data.change_date || '';

          return `${changer_name}<br/>${change_date}`;
        }      
      },
    ];

     // ag grid 컬럼 선언
     this.memoColumnDefs = [
      { headerName: '', field: '', width: 60, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
      }, 
      { headerName: '구분', field: 'malltype', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if( params.data.malltype == 'SCM' ) {
            return '<div style="color: orange; font-weight: 600;">' + '[ ' + params.data.malltype + ' ]' + '</div>';
          } else {
            return  '[ ' + params.data.malltype + ' ]';
          }
        },
      },
      { headerName: '거래처명', field: 'comname', width: 150, cellClass: 'cp center', 
        cellStyle(params: any) {
          if( params.data.comname ) {
            return {'background-color': '#d1eff9'};
          } else {
            return '';
          }
        }
      },
      { headerName: '코멘트', field: 'comment', width: 400, cellClass: 'cp left cell-wrap-text', cellRenderer: 'agGridHtmlComponent' },
      { headerName: '등록일', field: 'write_date', width: 150, cellClass: 'cp center' },
      { headerName: '삭제', field: 'delete', width: 80, cellClass: 'cp center',
        cellRendererFramework: AgGridButtonComponent,
        cellRendererParams: {
          action: 'delete',
          btnName: 'row 삭제',
          listaction: this.setOrderMemoDelete.bind(this)
        }
      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true,
      autoHeight: true,
    };

    this.domLayout = 'autoHeight';
    this.rowSelection = 'single';
    this.rowHeight = 120;

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };

    // 콤마 표시
    function getComma( num: any ) {
      var str = String(num);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }

    this.isRowSelectable = function(rowNode: any) {
      return rowNode.data ? ( rowNode.data.order_status != '3' && rowNode.data.order_status != '4' && rowNode.data.order_status != '5'
      && rowNode.data.order_status != '6' && rowNode.data.order_status != '7'  && rowNode.data.order_status != '10' ) : false;
    };

    this.isRowSelectablePay = function(rowNode: any) {
      return rowNode.data ? ( rowNode.data.pay_method == '0006' && rowNode.data.pay_amt > 0 ) : false;
    };

    this.isRowSelectableDelivery = function(rowNode: any) {
      return rowNode.data ? ( rowNode.data.isCancel < 1 && rowNode.data.delivery_charge > 0 ) : false;
    };
    
    this.rowClassRules = {
      'status7': function (params: any) {
        return params.data.order_status == '3' || params.data.order_status == '4' || params.data.order_status == '5'
        || params.data.order_status == '6' || params.data.order_status == '7'  || params.data.order_status == '10';
      },
      'cred': function (params: any) {
        return params.data.order_status == '3' || params.data.order_status == '4' || params.data.order_status == '5'
        || params.data.order_status == '6' || params.data.order_status == '7'  || params.data.order_status == '10'
        || ( parseInt(params.data.qty) > 0 && parseInt(params.data.refundQty) + parseInt(params.data.qty) < 1 );
      },
    };
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.summernoteConfig.height = 45;
    this.summernoteConfig.toolbar = [];

    // 로그인 정보 가져오기
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    }).unsubscribe();

    // 입력 파라미터 처리
    this.activatedRoute.queryParams.subscribe( async params => {
      this.params.pageNo = ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? '1' : params.pageNo;
      this.params.pageRow = ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? '20' : params.pageRow;
      this.params.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '0' : params.seq;
      this.params.iniseq = ( typeof params.iniseq == 'undefined' || params.iniseq == '') ? '' : params.iniseq;
      this.params.master_seq = ( typeof params.master_seq == 'undefined' || params.master_seq == '' ) ? '0' : params.master_seq;
      this.params.searchField = ( typeof params.searchField == 'undefined' || params.searchField == '' ) ? '' : params.searchField;
      this.params.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
      this.params.searchField2 = ( typeof params.searchField2 == 'undefined' || params.searchField2 == '' ) ? '' : params.searchField2;
      this.params.searchText2 = ( typeof params.searchText2 == 'undefined' || params.searchText2 == '' ) ? '' : params.searchText2;
      this.params.shop_gbn = ( typeof params.shop_gbn == 'undefined' || params.shop_gbn == '' ) ? '' : params.shop_gbn;
      this.params.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? this.utilService.getDate(moment().subtract(90, 'day')) : params.sdate;
      this.params.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? this.utilService.getDate('') : params.edate;
      this.params.paydate = ( typeof params.paydate == 'undefined' || params.paydate == '' ) ? '' : params.paydate;
      this.params.order_status = ( typeof params.order_status == 'undefined' || params.order_status == '') ? '' : params.order_status;
      this.params.searchTerm = ( typeof params.searchTerm == 'undefined' ) ? this.getDays(90) : params.searchTerm;
      this.params.searchType = ( typeof params.searchType == 'undefined' ) ? '' : params.searchType;
      this.params.topSearchText = ( typeof params.topSearchText == 'undefined' ) ? '' : params.topSearchText;
      this.params.pay_method = ( typeof params.pay_method == 'undefined' ) ? '' : params.pay_method;
      this.params.suspense = ( typeof params.suspense == 'undefined' ) ? '' : params.suspense;
      this.params.totalCount = ( typeof params.totalCount == 'undefined' ) ? '' : params.totalCount;

      // this.form.patchValue({ seq: this.params.seq });
      this.memoForm.patchValue({ orderSeq: this.params.seq });

      this.form.patchValue({
        order_date: moment().format('YYYY-MM-DD HH:mm:ss')
      });

      // 공통코드 가져오기
      this.getCommonList();

      // 은행 계좌 리스트 가져오기
      this.getCompanyAccountList();

      // 배송비 가져오기
      this.getConfig();

      // 메모 등록용 업체 리스트 가져오기
      this.getOrderMemoCustomerList();

      if( this.params.seq != 0 && this.params.seq != '' ) {

        // 주문 상세정보 가져오기
        this.getOrderDetail();

        // 메모 리스트 가져오기
        this.getOrderMemoList();
      } else if( this.params.iniseq != 0 && this.params.iniseq != '' ) {
        // 초도 주문 상세정보 가져오기
        this.getOrderDetailInitialList();
      }
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 120;
  };

  getRowMemoHeight = function(params) {
    if(params.data.comment.length > 29 ) {
      return 80;
    } else {
      return;
    }
  };


  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 판매처
    this.commonService.getCommonListCode('SLC').then( response => {
      if ( response.ResultCode ) {

        this.salerList.push({value: '', title: '판매경로를 선택하세요'});

        response.data.forEach( row => {
          this.salerList.push({
            value: row.common_code,
            title: row.common_name
          });
        });

      } else {
        this.salerList = [];
      }
    });

    // SHOP
    this.commonService.getCommonListCode('SHP').then( response => {
      if ( response.ResultCode ) {

        this.shopList.push({value: '', title: 'SHOP을 선택하세요'});

        response.data.forEach( row => {
          this.shopList.push({
            value: row.common_code,
            title: row.common_name
          });
        });

      } else {
        this.shopList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 은행 계좌 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCompanyAccountList() {
    this.basicService.getCompanyAccountList().then( response => {
      if ( response.ResultCode ) {

        this.bankList.push({value: '', title: '입금하실 은행 또는 카드를 선택하세요'});

        response.data.forEach( row => {
          this.bankList.push({
            value: row.seq,
            title: row.bank_name + ( row.bank_account ? ' : ' + row.bank_account : '' )
          });
        });

      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderDetail() {
    this.orderService.getOrderDetail( this.params.seq ).then( response => {
      if( response.ResultCode ) {
        this.orderInfo = response.data;
        
        this.form.patchValue( this.orderInfo );
        this.form.patchValue({
          detail: response.detail,
          pay: response.pay,
          delivery: response.delivery
        });

        // 공급자가 있으면 메모 콤보박스에 배경색 표시
        for( let detail of this.form.controls.detail.value) {
          for( let item of this.customerList ) {
            if( parseInt(detail.provider_seq) > 0 && item.value == detail.provider_seq ){
              item['bgcolor'] = true;
            }
          }
        }

        // 결제내역 합계 구하기
        let payList = this.form.controls.pay.value;
        if( payList.length > 0 ) {
          this.paySum.mileageSum = 0;
          this.paySum.otherSum = 0;
          this.paySum.cancelSum = 0;

          let realPayList: any = payList.filter(e => e.pay_method !== '0006');

          let payAmt: any = 0;
          payList.forEach(item => {
            payAmt += parseInt(item.pay_amt);

            if( item.pay_method == '0006' ) {
              this.paySum.mileageSum += parseInt(item.pay_amt);
            } else {
              this.paySum.otherSum += parseInt(item.pay_amt);
            }

            if( parseInt(item.pay_amt) < 0 ) this.paySum.cancelSum += parseInt(item.pay_amt);
          });

          if( this.paySum.otherSum == 0 && realPayList.length < 1 ) {
            if( this.orderInfo.order_status == '0' )
              this.paySum.otherSum = parseInt(this.orderInfo.org_order_amt);
            else
              this.paySum.otherSum = parseInt(this.orderInfo.order_amt) + parseInt(this.orderInfo.delivery_price);
          }

          if( this.paySum.mileageSum == payAmt ) {
            this.paySum.otherSum = 0;
          }
        } else {
          this.paySum.otherSum = parseInt(this.orderInfo.order_amt) + parseInt(this.orderInfo.delivery_price);
        }

        this.sendList.push({
          mem_no: this.form.controls.mem_no.value,
          name: this.form.controls.r_name.value,
          hp: this.form.controls.r_hp.value,
          id: this.form.controls.id.value
        });

      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 초도 주문상품 상세 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderDetailInitialList() {
    this.orderService.getOrderDetailInitialList( this.params.iniseq ).then( response => {
      if ( response.ResultCode ) {
        this.form.patchValue({
          detail : response.data
        });

      } else {
        this.toastrService.error( response.ResultMessage, '')
      }
    });

  }

  /*******************************************************************************
    설  명 : 주문 메모 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderMemoList() {
    this.orderService.getOrderMemoList( this.params.seq ).then( response => {
      if( response.ResultCode ) {
        this.memoList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문 메모 업체리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderMemoCustomerList() {
    this.orderService.getOrderMemoCustomerList().then( response => {
      if( response.ResultCode ) {
        const tmp: any = [];

        tmp.push({
          value: '0',
          title: '바마만'
        });

        for(let item of response.data ) {
          tmp.push({
            value: item.seq,
            title: `${item.comname} (${item.customer_id})`
          });
        }

        this.customerList = tmp;

      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

   /*******************************************************************************
    설  명 : 메모 선택
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onValueSelectChange($event) {
    this.memoForm.patchValue({
      customer_seq: $event.target.value
    });
  }

  /*******************************************************************************
    설  명 : 메모 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderMemoSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.memoForm, this.memoFormErrors);

    if (this.memoForm.valid) {
      this.orderService.setOrderMemoSave(this.memoForm).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '메모저장');

          this.memoForm.get('comment').setValue('');
          this.memoForm.get('comment').setErrors(null);
          this.memoForm.updateValueAndValidity();

          this.getOrderMemoList();
        } else {
          this.toastrService.error( response.ResultMessage, '메모저장');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '메모저장');
    }
  }

  /*******************************************************************************
    설  명 : 메모 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderMemoDelete( params: any ) {
    if( confirm("해당 메모를 삭제하시겠습니까?") ) {
      this.orderService.setOrderMemoDelete( params.data.seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage);

          this.getOrderMemoList();
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 주문정보 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    for( let i of this.bankList ) {
      if( i.value == this.form.value.account_seq) {
        if( i.value == '' ) {
          this.form.patchValue({
            bank: null
          });
        } else {
          let banktitle = i.title.split(':');
          this.form.patchValue({
            bank: banktitle[0]
          });
        }
      }
    }

    if (this.form.valid) {
      let detail: any = this.form.controls.detail.value;

      this.form.patchValue({
        detail_json: JSON.stringify(detail),
        detail: null,
        pay: null,      // 필요없는 파라이터 배열이라 전송 시 제외
        delivery: null  // 필요없는 파라이터 배열이라 전송 시 제외
      });

      this.orderService.setOrderSave(this.form).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          if( this.params.seq == '' || this.params.seq == '0' ) {

            this.form.patchValue({
              seq: response.orderSeq
            });

            this.memoForm.patchValue({
              orderSeq: response.orderSeq
            });

            this.params.seq = response.orderSeq;

            this.router.navigate(
              ['/order/detail'],
              { relativeTo: this.activatedRoute,
                queryParams: this.params,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
              }
            );            
          }

          // 주문 상세정보 가져오기
          this.getOrderDetail();

          // 메모 리스트 가져오기
          this.getOrderMemoList();

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 회원 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchMember() {
    const modalRef = this.modalService.open(MemberFindComponent, optionsLG);

    modalRef.result.then((result) => {

      this.form.patchValue({
        mem_no: result.data.mem_no,
        id: result.data.id,
        level: result.data.level,
        o_name: result.data.name,
        o_hp2: result.data.tel,
        o_hp: result.data.hp,
        o_email: result.data.email,
        grade: result.data.grade,
        grade_name: result.data.grade_name,
        o_zipcode: result.data.zipcode,
        o_address: result.data.address,
        o_address_detail: result.data.address_detail
      });

      // 선택한 회원에 맞는 상품 가격 가져오기
      this.getProductListForPropertyPrice();

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 회원 검색 addOn
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changeMember() {
    const modalRef = this.modalService.open(MemberFindComponent, optionsLG);

    modalRef.result.then((result) => {

      if( confirm( "[" + result.data.name + "] 회원으로 변경하시겠습니까?" ) ) {
        this.orderService.setChangeMember( result.data.mem_no, this.params.seq ).then( response => {
          if ( response.ResultCode ) {
            this.toastrService.success('비회원에서 회원으로 변경되었습니다.');
            this.getOrderDetail();
          } else {
            this.toastrService.error('비회원에서 회원으로 변경을 실패했습니다.');
          }
        });
      }

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 무료배송처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDeliveryFree() {
    if( confirm( "무료배송으로 변경하시겠습니까?" ) ) {
      this.form.patchValue({
        delivery_price: '0'
      });

      this.setOrderSave();
    }
  }
  
  /*******************************************************************************
    설  명 : 배송비 부과
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDeliveryCharge() {
    if( confirm( "배송비를 부과하시겠습니까?" ) ) {
      this.form.patchValue({
        delivery_price: this.delivery.delivery_amt
      });

      this.setOrderSave();
    }
  }
    
  /*******************************************************************************
    설  명 : 입금전 취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderDetailCancel() {
    const rows = this.orderGridApi.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error('취소할 내역을 선택하시기 바랍니다.');
    } else {
      const detail = [];
      let check: boolean;
      rows.forEach(data => {
        if(data.order_status != '0') check = true;

        if(data.total_amt < 0) check = true;

        detail.push(data.seq);
      });

      if( check === true ) {
        this.toastrService.error('주문접수 상태가 아닌 내역이 있습니다.');
      } else {
        if( confirm("선택하신 내역을 취소하시겠습니까?") ) {
          this.orderService.setOrderDetailCancel( this.params.seq, detail ).then( response => {
            if( response.ResultCode ) {
              this.toastrService.success(response.ResultMessage);

              this.getOrderDetail();
            } else {
              this.toastrService.error(response.ResultMessage);
            }
          });
        }
      }
    }
  }

  /*******************************************************************************
  설  명 : 환불 및 취소
  입력값 : 없음
  리턴값 : 없음
  *******************************************************************************/
  setOrderDetailRefund() {
    const rows = this.orderGridApi.getSelectedRows();
    
    if( rows.length < 1 ) {
      this.toastrService.error('취소할 내역을 선택하시기 바랍니다.');
    } else if (rows.length > 1) {
      this.toastrService.error('취소할 한개의 내역만 선택하시기 바랍니다.');
    } else if ( rows[0].order_status == '0' ) {
      this.toastrService.error('입금전 취소 처리를 하시기 바랍니다.');
    } else if ( rows[0].order_status == '0' || rows[0].order_status== '7' || rows[0].order_status== '10' || rows[0].total_amt < 0 ) {
      this.toastrService.error('주문접수 및 취소된 상태에서는 처리할 수 없습니다.');
//    } else if ( rows[0].status && rows[0].status.indexOf('1000') > -1 && rows[0].status.indexOf('2000') == -1 ) {
    } else if ( rows[0].status && rows[0].status.indexOf('1000') > -1 ) {
      this.toastrService.error('출고지시 상태에서는 처리할 수 없습니다.');
    } else if ( parseInt(rows[0].processable_qty) == 0 ) {
      this.toastrService.error('처리가능 수량이 없으면 처리할 수 없습니다.');
    } else {
      const modalRef = this.modalService.open(OrderStatusChangeComponent, optionsLG);

      modalRef.componentInstance.item = rows[0];
      modalRef.componentInstance.memberInfo = this.orderInfo;
      modalRef.componentInstance.order_mem_no = this.form.controls.mem_no.value;
      modalRef.componentInstance.isPurchase = true;

      modalRef.result.then((result) => {
        this.getOrderDetail();

      }, (reason) => {
      });
    }
  }

  /*******************************************************************************
  설  명 : 반품 및 교환 (입점업체전용)
  입력값 : 없음
  리턴값 : 없음
  *******************************************************************************/
  setOrderDetailReturn() {
    const rows = this.orderGridApi.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error('내역을 선택하시기 바랍니다.');
    } else if (rows.length > 1) {
      this.toastrService.error('한개의 내역만 선택하시기 바랍니다.');
    } else if ( rows[0].order_status == '0' ) {
      this.toastrService.error('입금전 취소 처리를 하시기 바랍니다.');
    } else if ( rows[0].order_status == '0' || rows[0].order_status== '7' || rows[0].order_status== '10' || rows[0].total_amt < 0 ) {
      this.toastrService.error('주문접수 및 취소된 상태에서는 처리할 수 없습니다.');
    // } else if ( rows[0].order_status != '8' && rows[0].order_status != '9' ) {
    //   this.toastrService.error('완료 상태가 아닌 내역은 처리할 수 없습니다.');
    } else if ( rows[0].status && rows[0].status.indexOf('1000') > -1 && rows[0].status.indexOf('2000') == -1 ) {
      this.toastrService.error('출고지시 상태에서는 처리할 수 없습니다.');
    } else if ( rows[0].provider_seq == '0' || rows[0].provider_seq == null ) {
      this.toastrService.error('입점 업체의 내역만 처리가 가능합니다.');
    } else {
      const modalRef = this.modalService.open(OrderStatusChangeComponent, optionsLG);

      modalRef.componentInstance.item = rows[0];
      modalRef.componentInstance.memberInfo = this.orderInfo;
      modalRef.componentInstance.order_mem_no = this.form.controls.mem_no.value;
      modalRef.componentInstance.isPurchase = false;

      modalRef.result.then((result) => {
        this.getOrderDetail();

      }, (reason) => {
      });
    }
  }

  /*******************************************************************************
    설  명 : 품절 체크페이지 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  soldOutProduct() {
    const nodes = this.orderGridApi.getSelectedNodes();

    const data: any = [];
    for(let node of nodes) {
      data.push( node.data.product_seq );
    }

    if( data.length < 1 ) {
      this.toastrService.error('항목을 선택해주세요.');
    } else {
      const url = '/product/list/soldout?seq=' + data;
      window.open("about:blank").location.href = url;
    }
  }

  /*******************************************************************************
    설  명 : 판매현황 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  salesStatus() {
    const nodes = this.orderGridApi.getSelectedNodes();

    const data: any = [];
    for(let node of nodes) {
      data.push( node.data.product_seq );
    }

    if( data.length < 1 ) {
      this.toastrService.error('항목을 선택해주세요.');
    } else {
      const url = '/product/salesstatus?seq=' + data;
      window.open("about:blank").location.href = url;
    }
  }
  
  copyButton() {
    let aux = document.createElement("input");
    aux.setAttribute("value", document.getElementById("textDiv").innerHTML);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);

    this.toastrService.success('복사되었습니다.');
  }

  statementBtn() {
    const modalRef = this.modalService.open(OrderStatementComponent, optionsLG);

    modalRef.componentInstance.detail = this.form.value.detail;
    modalRef.componentInstance.orderInfo = this.orderInfo;
    modalRef.componentInstance.sum = this.form.value.sum;
    modalRef.componentInstance.pay = this.form.value.pay;
    modalRef.componentInstance.paySum = this.paySum;

    modalRef.result.then((result) => {
      if( result ) {
      } else {
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 주문취소 내역 환불데이터 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCreateRefund() {
    if( confirm("환불데이터를 생성하시겠습니까?") ) {
      this.orderService.setCreateRefund( this.params.seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success(response.ResultMessage);

          this.getOrderDetail();
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 주문내역 매입/입점 변경
  *******************************************************************************/
  setOrderProductProvider() {
    const rows = this.orderGridApi.getSelectedRows();

    if(rows.length < 1) {
      this.toastrService.warning('변경할 내역을 선택하시기 바랍니다.');
      return false;
    } else {
      const seqArray: any = [];
      let check: boolean;
      rows.forEach(item => {
        if( item.status && ( item.status.indexOf('1000') > -1 || item.status.indexOf('2000') > -1 ) ) check = true;
        
        seqArray.push(item.seq);
      });

      if( check === true ) {
        this.toastrService.error('출고지시나 출고완료된 이후에는 변경이 불가합니다.');
      } else {
        this.orderService.setOrderProductProvider( seqArray ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');

            this.getOrderDetail();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 상품 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  productAdd() {
    const modalRef = this.modalService.open(ProductFindPropertyComponent, optionsXXL);

    modalRef.componentInstance.mem_no = this.form.controls.mem_no.value;

    modalRef.result.then((result) => {
      if( typeof result != 'undefined' ) {
        // 결제 이후에는 master로 묶어서 새롭게 주문을 추가
        if( this.params.seq !== '0' && this.orderInfo.order_status !== '0' ) {
          this.orderService.setOrderProductAdd( this.params.seq, result ).then( response => {
            if( response.ResultCode ) {
              this.toastrService.success( response.ResultMessage, '');

              this.getOrderDetail();
            } else {
              this.toastrService.error( response.ResultMessage, '');
            }
          });
        // 주문접수의 경우 해당 주문에 detail로 추가
        } else {
          let tmp = this.form.controls.detail.value;
          let dupCount: number = 0;

          const newResult: any = [...tmp];
          result.forEach(obj => {
            const duplicate = tmp.filter(function (item) {
              return (item.product_seq === obj.seq && item.product_property_seq === obj.property_seq && item.order_status == '0');
            });

            if( duplicate.length > 0 ) {
              dupCount += 1;
            } else {
              obj.order_status = 0;
              obj.qty = 1;
              obj.discount_amt = 0;
              obj.buy_price = obj.buy_price;
              obj.amt = obj.consumer_price;
              obj.total_amt = obj.amt * obj.qty;
              obj.mileage = ( this.checkMileage(obj) === false ) ? 0 : (obj.total_amt / 100);
              obj.product_seq = obj.seq;
              obj.product_property_seq = obj.property_seq;
              obj.seq = null;

              newResult.push(obj);
            }
          });

          if( dupCount > 0 ) {
            this.toastrService.error( '중복된 상품이 ' + dupCount + ' 건 존재합니다. 수량을 변경하시기 바랍니다.', '');
          }

          if( tmp.length !== newResult.length ) {
            this.form.patchValue({ detail: newResult });

            if( this.params.seq !== '0' ) {
              this.setOrderSave();
            }
          }
        }
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 회원 선택 시 상품 가격 가져오기
    입력값 : 상품 정보
    리턴값 : 없음
  *******************************************************************************/
  getProductListForPropertyPrice() {
    const rowData = [];
    const seqArray = [];
    this.orderGridApi.forEachNode(node => {
      rowData.push(node.data);
      seqArray.push(node.data.product_property_seq);
    });

    // 선택된 상품에 한해서 가격정보 가져오기
    this.productService.getProductListForPropertyPrice( this.form.controls.mem_no.value, seqArray ).then( response => {
      if( response.ResultCode ) {

        response.data.forEach(item => {
          rowData.forEach(row => {
            if( row.order_status == '0' && item.property_seq == row.product_property_seq ) {
              row.unit_price = item.unit_price;
              row.amt = item.consumer_price;
              row.buy_price = item.buy_price;
              row.event_seq = item.event_seq;
              row.event_name = item.event_name;
              row.total_amt = ( parseInt(item.consumer_price) - parseInt(row.discount_amt) ) * row.qty;
              // row.mileage = item.mileage * row.qty;
              row.mileage = ( this.checkMileage(row) === false ) ? 0 : (row.total_amt / 100);
            }
          });
        });

        this.orderGridApi.applyTransaction({ update: [rowData] });

      } else {
        this.toastrService.error( response.ResultMessage, '상품 가격정보 가져오기');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 상품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  productDelete() {
    let selectedRowData = this.orderGridApi.getSelectedRows();

    this.orderGridApi.applyTransaction({ remove: selectedRowData });

    let rowData = [];
    this.orderGridApi.forEachNode(node => rowData.push(node.data));

    this.form.patchValue({ detail: rowData });
  }

  /*******************************************************************************
    설  명 : 결제 확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderPayComplete() {
    const nodes = this.form.controls.pay.value;

    if( nodes.length > 0 ) {
      this.toastrService.error('이미 결제내역이 있습니다.');
    } else {
      if( confirm("결제 확인 처리하시겠습니까?") ) {
        this.orderService.setOrderPayComplete( this.params.seq ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success(response.ResultMessage);

            this.getOrderDetail();
          } else {
            this.toastrService.error(response.ResultMessage);
          }
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 여신 대체 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderPayCreditReplace() {
    const nodes = this.payGridApi.getSelectedNodes();

    if( nodes.length < 1 ) {
      this.toastrService.error('대체할 내역을 선택하시기 바랍니다.');
    } else if( nodes.length > 1 ) {
      this.toastrService.error('한 개의 내역만 선택하시기 바랍니다.');
    } else {
      const data = nodes[0].data;

      if( data.pay_method !== '0007' ) {
        this.toastrService.error('여신인 내역만 처리가 가능합니다.');
      } else {
        if( confirm("여신 대체 처리하시겠습니까?") ) {
          this.orderService.setOrderPayCreditReplace( data.seq ).then( response => {
            if( response.ResultCode ) {
              this.toastrService.success(response.ResultMessage);

              this.getOrderDetail();
            } else {
              this.toastrService.error(response.ResultMessage);
            }
          });
        }
      }
    }
  }

  /*******************************************************************************
    설  명 : 결제내역 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  paymentAdd( item: any ) {
    const modalRef = this.modalService.open(OrderPaymentAddComponent, optionsLG);

    let sumData = this.form.controls.sum.value;
    let payData = this.form.controls.pay.value;

    modalRef.componentInstance.orderSeq = this.params.seq;
    modalRef.componentInstance.hp = this.orderInfo.o_hp;

    modalRef.componentInstance.mem_no = this.form.value.mem_no;

    modalRef.componentInstance.item = item;
    modalRef.componentInstance.payList = payData;

    modalRef.componentInstance.pay_amt = parseInt(sumData[0].sum_total_amt) + parseInt(this.orderInfo.delivery_price);

    modalRef.result.then((result) => {
      if( result ) {
        this.getOrderDetail();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 마일리지 결제내역 취소 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMileagePayCancel() {
    const rows = this.payGridApi.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error('취소처리할 내역을 선택하시기 바랍니다.');
    } else if( rows[0].pay_method !== '0006' ) {
      this.toastrService.error('마일리지로 결제한 내역만 처리가 가능합니다.');
    } else {
      if( confirm("선택하신 내역을 취소하시겠습니까?") ) {
        this.orderService.setMileagePayCancel( this.params.seq, rows[0].seq ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success(response.ResultMessage);

            this.getOrderDetail();
          } else {
            this.toastrService.error(response.ResultMessage);
          }
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 배송비 내역 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deliveryChargeAdd( data: any ) {
    const modalRef = this.modalService.open(OrderDeliveryChargeAddComponent, optionsLG);

    modalRef.componentInstance.order_seq = this.params.seq;
    modalRef.componentInstance.data = data;

    modalRef.result.then((result) => {
      if( result ) {
        this.getOrderDetail();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 배송비 내역 취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDeliveryChargeDelete() {
    const rows = this.deliveryGridApi.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error('취소할 내역을 선택하시기 바랍니다.');
    } else {
      let tmp: any = [];
      tmp = rows.map((obj: any) => obj.seq);

      if( confirm("선택하신 배송비 내역을 취소하시겠습니까?") ) {
        this.orderService.setDeliveryChargeDelete( tmp ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success(response.ResultMessage);

            this.getOrderDetail();
          } else {
            this.toastrService.error(response.ResultMessage);
          }
        });
      }
    }
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onOrderGridReady(params) {
    this.orderGridApi = params.api;
    this.orderGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onOrderSumGridReady(params) {
    this.orderSumGridApi = params.api;
    this.orderSumGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 택배송장 확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deliveryButton( info: any ) {
    let com = info.split('[')[0];
    let delNum = info.split('_')[1];

    delNum = delNum.replace(/\-/g,'');

    if ( com == "우체국택배" ){
      var gourl = "http://service.epost.go.kr/trace.RetrieveRegiPrclDeliv.postal?sid1=" + delNum;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( com == "로젠택배" ){
      var gourl = "https://www.ilogen.com/web/personal/trace/"+delNum;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( com == "한진택배" ){
      var gourl = "https://www.hanjin.co.kr/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2="+delNum;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( com == "CJ 대한통운" ){
//      var gourl = "http://www.doortodoor.co.kr/tracking/jsp/cmn/Tracking_new.jsp?QueryType=3&pTdNo="+delNum;
      var gourl = "https://trace.cjlogistics.com/web/detail.jsp?slipno="+delNum;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else if ( com == "롯데택배" ){
      var gourl = " https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo="+delNum;
      window.open(gourl, 'popup','scrollbars=yes, width=1200, height=900');
    } else {
      this.toastrService.error('발송 전입니다.');
    }
  }

  /*******************************************************************************
    설  명 : 상품번호 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  productSeqButton( params: any ) {
    const url = '/product/list/add?seq=' + params.data.product_seq;
    window.open("about:blank").location.href = url;
  }
  
  /*******************************************************************************
    설  명 : 그리드 셀 클릭시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    let product_seq = $event.data.product_seq;
    if( $event.colDef.field === 'selection' ) {
      $event.node.setSelected(true);
    } else if( $event.colDef.field === 'thumbnail_url' ) {
      const url = this.baseURL + '/product/info?productSeq=' + product_seq;
      window.open("about:blank").location.href = url;
    }
  }

  /*******************************************************************************
    설  명 : 주문 금액 변경 모달창 띄우기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked(event: any) {  
    if( this.memberInfo.id == 'o2oz2016' || this.memberInfo.id == 'bluesea88' || this.memberInfo.id == 'kej5751') {
      const modalRef = this.modalService.open(OrderAmtEditComponent, optionsLG);

      modalRef.componentInstance.order_seq = this.params.seq;
      modalRef.componentInstance.data = event.data;

      modalRef.result.then((result) => {
        if( result ) {
          this.getOrderDetail();
        }
      }, (reason) => {
      });
    }
  }
  
  /*******************************************************************************
    설  명 : 공급자 연락처 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openCustomerInfo( params: any ) {
    const modalRef = this.modalService.open(CustomerTabInfoComponent, optionsLG);

    modalRef.componentInstance.modal = true;
    modalRef.componentInstance.seq = params.data.provider_seq;

    modalRef.result.then((result) => {

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 탭 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onTabChange(value: any) {
    this.activeTab = value;

    if( this.activeTab == 0 ) this.getOrderDetail();
  }

  /*******************************************************************************
    설  명 : 셀 변경 종료 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellEditingStopped( event: any ) {
    let rowData = event.data;

    // 할인 입력 시
    if( event.colDef.field == 'discount_amt' ) {
      if( parseInt(rowData.discount_amt) > parseInt(rowData.org_amt) ) {
        this.toastrService.error('할인금액이 판매단가보다 더 큽니다.');
        rowData.discount_amt = 0;
        event.node.setData( rowData );
        return false;
      }
    }
    // 할인값에 아무것도 없을경우 0으로 바인딩  
    if (rowData.discount_amt === "") {
      rowData.discount_amt="0";
    }
    const calc_amt = parseInt(rowData.amt) - parseInt(rowData.discount_amt);

    rowData.total_amt = calc_amt * rowData.qty;

    // 마일리지 필드를 직접 수정이 아닐 때만 적용
    if( event.colDef.field != 'mileage' ) rowData.mileage = rowData.total_amt / 100;

    // 차액마일리지 상품이 아니면서
    // 이벤트 상품정보의 마일리지가 0 일 때는 무조건 0 으로 적용
    // if( rowData.product_seq != '351096' && ( parseInt(rowData.event_seq) > 0 && rowData.event_mileage == '0' ) ) 
    //   rowData.mileage = 0;

    // 회원이 일반이 아닌 경우 마일리지 무조건 0
    // if( this.form.controls.grade.value != '0001' && this.form.controls.grade.value != '0000' ) 
    //   rowData.mileage = 0;

    // 관리자 레벨부터는 마일리지 무조건 0
    // if( this.form.controls.level.value >= '80' ) 
    //   rowData.mileage = 0;
    
    // 마일리지 지급여부 체크
    if( this.checkMileage(rowData) === false ) rowData.mileage = 0;

    event.node.setData( rowData );

    this.onRowDataChanged( event );

    // if( this.params.seq !== '0' ) {
    //   this.setOrderSave();
    // }
  }

  /*******************************************************************************
    설  명 : 마일리지 지급여부 체크
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  checkMileage( rowData: any ) {
    let check: boolean = true;

    // 차액마일리지 상품이 아니면서 이벤트 상품정보의 마일리지가 0 일 때는 무조건 0 으로 적용
    if(
      rowData.product_seq != '351096' && 
      ( parseInt(rowData.event_seq) > 0 && rowData.event_mileage == '0' ) 
    ) check = false;

    // 회원이 일반이 아닌 경우 마일리지 무조건 0
    if( 
      this.form.controls.grade.value != '' && 
      this.form.controls.grade.value != '0000' && 
      this.form.controls.grade.value != '0001'
    ) check = false;

    // 특정 ID는 마일리지 0
    if(
      this.form.controls.id.value == 'lotteon' ||
      this.form.controls.id.value == 'baemin' ||
      this.form.controls.id.value == 'myomee' ||
      this.form.controls.id.value == 'rocketgrowth1' ||
      this.form.controls.id.value == 'rocketgrowth'
    ) check = false;

    // 관리자 레벨부터는 마일리지 무조건 0
    if(
      this.form.controls.level.value != '' && 
      this.form.controls.level.value >= '80' 
    ) check = false;

    return check;
  }

  /*******************************************************************************
    설  명 : 셀 리사이즈 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onColumnResized(params) {
    setTimeout(() => {
      params.api.resetRowHeights();
    }, 0);
  }

  onColumnVisible(params) {
    setTimeout(() => {
      params.api.resetRowHeights();
    }, 0);
  }

  /*******************************************************************************
    설  명 : 그리드 검색
  *******************************************************************************/
  onQuickFilterChanged( event: any ) {
    const searchText: any = document.getElementById( event.target.id );
    this.orderGridApi.setQuickFilter(searchText.value);
  }
  
  /*******************************************************************************
    설  명 : 주문내역 총 합계 구하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDataChanged( event: any ) {
    let sumQty = 0;
    let sumAmt = 0;
    let sumDiscountAmt = 0;
    let sumMileage = 0;
    let sumCancel = 0;
    let sumTotalAmt = 0;

    event.api.forEachNodeAfterFilter( function(node) {
      if( node.data.order_status != 7 ) {
        sumQty += parseInt(node.data.qty);
        sumAmt += parseInt(node.data.amt);
        sumDiscountAmt += parseInt(node.data.discount_amt) * parseInt(node.data.qty);
        sumMileage += parseInt(node.data.mileage);
        sumCancel += ( parseInt(node.data.total_amt) < 0 ) ? parseInt(node.data.total_amt) : 0;
        sumTotalAmt += parseInt(node.data.total_amt);
      }
    });

    let tmp: any = [];
    tmp.push({
      sum_qty: sumQty,
      sum_amt: sumAmt,
      sum_discount_amt: sumDiscountAmt,
      sum_mileage: sumMileage,
      sum_cancel: sumCancel,
      sum_total_amt: sumTotalAmt,
    });

    this.form.patchValue({ sum: tmp });

    if( this.params.seq == 0 || this.params.seq == '' ) this.getDeliveryAmt();
  }

  /*******************************************************************************
    설  명 : 환경설정 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getConfig() {
    this.siteConfigService.getConfig(CONFIG_KEY).then( response => {
      if( response.ResultCode ) {
        this.delivery.delivery_amt = response.data.delivery_amt;
        this.delivery.delivery_free_condition = response.data.delivery_free_condition;
        this.delivery.delivery_overcharge = response.data.delivery_overcharge;
      } else {
        this.toastrService.error( response.ResultMessage );
      }
    });
  }

  /******************************************************************************
    설  명 : 배송비 계산처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getDeliveryAmt() {
    this.form.patchValue({
      delivery_price: parseInt(this.delivery.delivery_amt)
    });

    if( this.form.value.sum[0].sum_amt >= this.delivery.delivery_free_condition ) {
      this.form.patchValue({
        delivery_price: '0'
      })
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onPayGridReady(params) {
    this.payGridApi = params.api;
    this.payGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 결제내역 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onPayGridCellClicked( event: any ) {
    let field: any = event.colDef.field;

    if( field === 'pay_method_name' || field === 'pay_date' || field === 'pay_amt' || field === 'writer_name' ) {
      this.paymentAdd( event.data );
    }    
  }

  /*******************************************************************************
    설  명 : 결제내역 그리드 셀 변경 종료 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onPayGridCellEditingStopped( event: any ) {
    let rowData = event.data;
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onDeliveryGridReady(params) {
    this.deliveryGridApi = params.api;
    this.deliveryGridColumnApi = params.columnApi;
  }
  
  /*******************************************************************************
    설  명 : 셀 클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onDeliveryGridRowDoubleClicked(event: any) {
    this.deliveryChargeAdd(event.data);
  }
  
  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onMemoGridReady(params) {
    this.memoGridApi = params.api;
    this.memoGridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 메모 input에서 엔터키 입력 시
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  setKeyPressMemo( event ) {
    if( event.key == 'Enter' ) {
      this.setOrderMemoSave();
    }
  }

  /*******************************************************************************
    설  명 : 주문자 정보 바인딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  orderInfoBinding() {
    if( this.form.value.o_name == '' || this.form.value.o_name == null ) {
      this.toastrService.error("주문자 정보를 먼저 입력해주세요.");
    } else {
      this.form.patchValue({
        r_name: this.form.value.o_name,
        r_hp: this.form.value.o_hp,
        r_zipcode: this.form.value.o_zipcode,
        r_address: this.form.value.o_address,
        r_address_detail: this.form.value.o_address_detail
      });
    }
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  getDays(day: number) {
    const todaysDate = moment();

    let toDate: any;

    if (day === 0) {
      return 0;
    } else {
      toDate = moment().add(day * -1, 'days');
    }

    return toDate.diff(todaysDate, 'days');
  }
  
  /*******************************************************************************
    설  명 : 주소 찾기
    입력값 : data
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      r_zipcode: data.zip,
      r_address: data.addr
    });

    $("#r_address_detail").focus();
  }

  /*******************************************************************************
    설  명 : 리스트로 돌아가기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.params.seq = '';

    if( typeof this.params.sdate === 'object' ) 
      this.params.sdate = this.utilService.getDateStr(this.params.sdate);

    if( typeof this.params.edate === 'object' ) 
      this.params.edate = this.utilService.getDateStr(this.params.edate);

    this.router.navigate(
      ['/order/list'],
      { relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 상품 수취인 정보 내용 sms 발송
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  sendInfoSms() {
    let join = '';
    if ( this.consign_infoSelect == undefined ) {
      this.consign_infoSelect = '';
    }

    join = this.consign_infoSelect;

    if ( this.consign_infoSelect == '' ) {
      this.toastrService.error('빈값입니다. 메뉴를 선택하세요.');
    } else {
      const modalRef = this.modalService.open(ModalSmsComponent, options);

      modalRef.componentInstance.sendList = this.sendList;
      modalRef.componentInstance.contents = join;

      modalRef.result.then((result) => {
      }, (reason) => {
      });

    }
  }

  /*******************************************************************************
    설  명 : 상품 수취인 정보 은행 및 카드 정보 sms 발송
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  sendInfoSms1() {
    let join = '';
    if ( this.send_bankSelect == undefined ) {
      this.send_bankSelect = '';
    }

    join = this.send_bankSelect;

    if ( this.send_bankSelect == '' ) {
      this.toastrService.error('빈값입니다. 메뉴를 선택하세요.');
    } else {
      const modalRef = this.modalService.open(ModalSmsComponent, options);

      modalRef.componentInstance.sendList = this.sendList;
      modalRef.componentInstance.contents = join;

      modalRef.result.then((result) => {
      }, (reason) => {
      });

    }
  }

  /*******************************************************************************
    설  명 : 상품 수취인 정보 select event
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  consign_infoSelectChange(event) {
    this.consign_infoSelect = event.target.value;
  }

  /*******************************************************************************
    설  명 : 상품 수취인 정보 은행 select event
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  send_bankSelectChange(event) {
    this.send_bankSelect = event.target.value;
  }

  /*******************************************************************************
    설  명 : 세나 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openSenaProcess( params: any ) {
    const modalRef = this.modalService.open(ProductSenaProcessComponent, optionsXL);

    modalRef.componentInstance.params = params;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 엑셀 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setExcelOrder() {
    const contents = [`
      <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
        <caption style="font-size:14pt;"><strong>${this.orderInfo.o_name} 주문내역</strong></caption>
        <colgroup>
          <col style="width:85px;" />
          <col style="width:110px;" />
          <col style="width:100px;" />
          <col style="width:300px;" />
          <col style="width:100px;" />
          <col style="width:60px;" />
          <col style="width:100px;" />
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:90px;" />
          <col style="width:90px;" />
          <col style="width:90px;" />
          <col style="width:90px;" />
          <col style="width:90px;" />
          <col style="width:100px;" />
        </colgroup>
        <tbody>
        <tr align="center" bgColor="#f5f7f7">
          <td>상품번호</td>
          <td>제조사</td>
          <td>이미지</td>
          <td>상품명</td>
          <td>이벤트</td>
          <td>수량</td>
          <td>취소후수량</td>
          <td>구매단가</td>
          <td>할인</td>
          <td>판매단가</td>
          <td>마일리지</td>
          <td>소계</td>
          <td>취소후소계</td>
          <td>출고지시</td>
          <td>출고/미출고</td>
          <td>품절상태</td>
          <td>주문상태</td>
        </tr>
    `];

    let data = this.form.value.detail;

    if( data.length < 1 ) {
      this.toastrService.error('엑셀로 다운 받을 데이터가 없습니다.');
      return false;
    }

    data.forEach(element => {

      let img = '';
      img = `<img src="${element.thumbnail_url}" height="100" />`;

      let productName = element.product_name;
      productName = productName.replace(/<br>/g,'<br style="mso-data-placement:same-cell;">');
      productName = productName.replace(/<br\/>/g,'<br style="mso-data-placement:same-cell;">');
      productName = productName.replace(/<BR>/g,'<br style="mso-data-placement:same-cell;">');
      productName = productName.replace(/<BR\/>/g,'<br style="mso-data-placement:same-cell;">');

      let refundQty = parseInt(element.refundQty) || 0;
      let qty = parseInt(element.qty);

      let unit_price = element.unit_price;
      let amt = element.amt;
      let calc_amt = parseInt(element.amt) - parseInt(element.discount_amt);
      let discount_amt = parseInt(element.discount_amt);
      let calc_total_amt = ( qty + refundQty ) * ( amt - discount_amt );

      let statusArr = ( element.status !== null ) ? element.status.split(',') : '';

      let statusBadge: any = '';
      if( statusArr !== '' ) {
        statusArr.forEach((value,index) => {
          if( index > 0 ) statusBadge = statusBadge + '<br style="mso-data-placement:same-cell;">';
          if ( value == '1000' ) {
            statusBadge = statusBadge + '출고지시';
          } else if ( value == '2000' ) {
            statusBadge = statusBadge + '출고완료';
          } else {
            statusBadge = statusBadge + '문의';
          }
        });
      }

      let out_qty: number = ( element.out_qty !== null && element.out_qty !== undefined ) ? element.out_qty : 0;
      let incompleteOutQty: number = ( element.incompleteOutQty !== null && element.incompleteOutQty !== undefined ) ? element.incompleteOutQty : 0;

      let soldOutBadge: any = '';
      if ( element.solodOut == '0' ) soldOutBadge = '판매중';
      else if ( element.solodOut == '1' ) soldOutBadge = '일시품절';
      else if ( element.solodOut == '2' ) soldOutBadge = '영구품절';
      else soldOutBadge = '';

      let returnStatus: any = ( element.return_status !== null ) ? element.return_status : '';
      let orderDate = moment(element.order_date).format('YY-MM-DD');
      let outDateArr = ( element.out_date ) ? element.out_date.split(',') : '';

      let outDate: any = '';
      if( outDateArr !== '' ) {
        outDateArr.forEach((value,index) => {
          if( index > 0 ) outDate = outDate + '<br style="mso-data-placement:same-cell;">';
          outDate = outDate + moment(value).format('YY-MM-DD');
        });
      }

      let status = '';
      if( qty > 0 && (refundQty + qty) < 1 ) {
        status = '';
      } else {
        if ( element.order_status == '3' || element.order_status == '4' || element.order_status == '5' || element.order_status == '6' || element.order_status == '10' ) {
          status = element.order_status_name + '<br style="mso-data-placement:same-cell;">' + '<span style="color: blue">' + returnStatus + '</span>';
        } else {
          status = element.order_status_name;
        }
      }

      let deliveryInfoArr: any = ( element.delivery_info !== null ) ? element.delivery_info.split(',') : '';
      let deliveryInfo: any = '';
      if( deliveryInfoArr !== '' ) {
        deliveryInfoArr.forEach((value, index) => {
          if( index > 0 ) deliveryInfo = deliveryInfo + '<br style="mso-data-placement:same-cell;">';
          let valueArr = value.split('_');
          deliveryInfo = deliveryInfo + valueArr[0] + '<br style="mso-data-placement:same-cell;">' + valueArr[1];
        });
      }

      let cancelBgColor: any = '';
      let cancelColor: any = '';
      if( 
          element.order_status == '3' || 
          element.order_status == '4' || 
          element.order_status == '5' || 
          element.order_status == '6' || 
          element.order_status == '7' || 
          element.order_status == '10' 
        ) {
        cancelBgColor = ' background-color:#ffc !important; ';
      }

      if( 
          element.order_status == '3' || 
          element.order_status == '4' || 
          element.order_status == '5' || 
          element.order_status == '6' || 
          element.order_status == '7' || 
          element.order_status == '10' ||
          ( parseInt(element.qty) > 0 && parseInt(element.refundQty) + parseInt(element.qty) < 1 )
        ) {
          cancelColor = ' color:red !important; ';
      }

      contents.push(`
        <tr style="height:150px;${cancelBgColor}${cancelColor}">
          <td style="text-align:center;">${element.product_seq}<br style="mso-data-placement:same-cell;">${element.product_property_seq}</td>
          <td style="text-align:center;">${element.brand_name}<br style="mso-data-placement:same-cell;">${element.category_name}</td>
          <td style="text-align:center;">${img}</td>
          <td style="text-align:left;">${productName}<br style="mso-data-placement:same-cell;"><span style="font-weight:bold;">${element.display_color_name ? element.display_color_name : ''} / ${element.display_size_name ? element.display_size_name : ''}</span><br style="mso-data-placement:same-cell;">${deliveryInfo}</td>
          <td style="text-align:center;">${ ( element.event_name !== null ) ? element.event_name : '' }</td>
          <td style="text-align:center;">${element.qty}</td>
          <td style="text-align:center;">${ element.total_amt < 0 ? '' : '<span style="font-weight:bold;">' + qty + refundQty + '<span>' }</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.buy_price}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.discount_amt}</td>
          <td style="text-align:right;">${ ( unit_price != amt ? '<span style="color:#898989;">' + this.setComma(unit_price) + '</span><br style="mso-data-placement:same-cell;">' : '' ) + this.setComma(amt) + ( element.discount_amt > 0 ? '<br style="mso-data-placement:same-cell;"><span style="color:cred;">' + this.setComma(calc_amt) + '</span>' : '' ) }</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.mileage}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.total_amt}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${ calc_total_amt > 0 ? '<span style="font-weight:bold;">' + calc_total_amt + '<span>' : '' }</td>
          <td style="text-align:center;">${statusBadge}</td>
          <td style="text-align:center;">${ out_qty + ' / ' + ( incompleteOutQty < 0 ? 0 : incompleteOutQty ) }</td>
          <td style="text-align:center;">${soldOutBadge}</td>
          <td style="text-align:center;">${ ( status != '' ) ? status + '<br style="mso-data-placement:same-cell;">' + ( outDate != '' ? outDate : orderDate ) : '' }</td>
        </tr>
        </tbody>
      `)
    })
    contents.push(`</table>`);

    let today = new Date();

    const fileName = `bikemart_orderList_${[today.getFullYear(), today.getMonth()+1, today.getDate()].join('')}`

    this.printExcel(fileName, contents.join(''))
  }
  
  /*******************************************************************************
    설  명 : 엑셀 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  printExcel(fileName, contents) {
    const exportContents = `
      <html xmlns:x="urn:schemas-microsoft-com:office:excel">
      <head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
      <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet</x:Name>
      <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions>
      </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head>
      <body>${contents}</body></html>
    `
    const blob = new Blob([exportContents], {type: "application/csv;charset=utf-8;"})
    const elem = document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = `${fileName}.xls`
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }
  
  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  setComma( value: any ) {
    let str = String(value);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }
  
}