/*******************************************************************************
  설  명 : 상품관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { ProductService } from '@app/service/product.service';
import { CategoryService } from '@app/service/category.service';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  public statistic: any = {
    total: 0,
    sales: 0,
    standby: 0,
    soldout: 0,
    notcolor: 0,
    notsize: 0
  };

  public productList: any = [];
  public categoryList: any = [];
  public soldoutList: any = [];

  public baseURL = config.baseUrl;

  // 그리드 관련 선언
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  components: any;
  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridImageComponent: AgGridImageComponent,
    agGridHtmlComponent: AgGridHtmlComponent
  };

  public params: any = {
    pageNo: 1,
    pageRow: 20,
    totalCount: 0,
    seq: '',
    searchField: 'product_seq',
    searchText: '',
    status: '',
    sdate: '',
    edate: '',
    is_display: '',
    category_code: '',
    is_card_installment: '',
    mileage: '',
    safe_stock: '',
    approval_yn: '1', 
    is_mach: ''

  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private productService: ProductService,
    private categoryService: CategoryService,
    private toastrService: ToastrService,
    private agGridExComponent: AgGridExComponent
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: '', width: 40, cellClass: 'cp center',
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
      { headerName: '상품번호/옵션번호', field: 'property_seq', width: 80, cellClass: 'cp center ag-cell130h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          return params.data.seq + '<br/>' + params.data.property_seq;
        }
      },
      { headerName: '부모승인여부/자식승인여부', field: 'approval_yn', width: 101, cellClass: 'center ag-cell130h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let approvalYn = ( params.data.approval_yn == 1 ) ? '<span class="badge badge-success f12">승인</span>' : '<span class="badge badge-danger f12">미승인</span>';
          let propertyApprovalYn = ( params.data.property_approval_yn == 1 ) ? '<span class="badge badge-success f12 mt5">승인</span>' : '<span class="badge badge-danger f12 mt5">미승인</span>';

          return approvalYn + '<br/>' + propertyApprovalYn;
        }
      },
      { headerName: '부모품절여부/자식품절여부', field: 'status', width: 101, cellClass: 'center ag-cell130h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let parentStatus: any;
          let childStatus: any;

          if ( params.data.parent_status == '0' ) parentStatus = '<span class="badge badge-success f12">판매중</span>';
          else if ( params.data.parent_status == '1' ) parentStatus = '<span class="badge badge-warning f12">일시품절</span>';
          else if ( params.data.parent_status == '2' ) parentStatus = '<span class="badge badge-danger f12">영구품절</span>';
          else parentStatus = '';

          if ( params.data.status == '0' ) childStatus = '<span class="badge badge-success f12 mt5">판매중</span>';
          else if ( params.data.status == '1' ) childStatus = '<span class="badge badge-warning f12 mt5">일시품절</span>';
          else if ( params.data.status == '2' ) childStatus = '<span class="badge badge-danger f12 mt5">영구품절</span>';
          else childStatus = '';

          return parentStatus + '<br/>' + childStatus;
        }
      },
      { headerName: '표시여부(상품/옵션)', field: 'is_display', width: 87, cellClass: 'center ag-cell130h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let isDisplay = params.data.is_display;
          let propertyIsDisplay = params.data.property_is_display;
          let displayBadge = ( isDisplay == 1 ) ? '<span class="badge badge-success f12">표시</span>' : '<span class="badge badge-danger f12">미표시</span>';
          let propertyDisplayBadge = ( propertyIsDisplay == 1 ) ? '<span class="badge badge-success f12 mt5">표시</span>' : '<span class="badge badge-danger f12 mt5">미표시</span>';

          return displayBadge + ( propertyIsDisplay !== undefined ? '<br/>' + propertyDisplayBadge : '' );
        }
      },
      { headerName: '이미지', field: 'thumbnail_url', width: 140, cellClass: 'cp center ag-cell130h', cellRenderer: 'agGridImageComponent' },
      { headerName: '카테고리/브랜드/상품명', field: '', width: 300, cellClass: 'ag-cell130h-br cell-wrap-text', autoHeight: true, cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let category_name = ( params.data.category_name ) ? params.data.category_name + '<br/>' : '';
          let brand_name = ( params.data.brand_name ) ? '[ ' + params.data.brand_name + ' ]<br/>' : '';
          let product_name = ( params.data.product_name ) ? params.data.product_name : '';

          return category_name + brand_name + product_name;
        }
      },
      { headerName: '색상/사이즈', field: '', width: 93, cellClass: 'left ag-cell130h-br cell-wrap-text', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let color_name = ( params.data.color_name ) ? params.data.color_name : '';
          let display_color_name = ( params.data.display_color_name ) ? '<span class="fb">' + params.data.display_color_name + '</span>' : '';
          let size_name = ( params.data.size_name ) ? params.data.size_name : '';
          let display_size_name = ( params.data.display_size_name ) ? '<span class="fb">' + params.data.display_size_name + '</span>' : '';

          // return color_name + display_color_name + '<br/>' + size_name + display_size_name;
          return display_color_name + ' / ' + display_size_name;
        }
      },
      { headerName: '공급사', field: 'provider_name', width: 100, cellClass: 'left ag-cell130h-br cell-wrap-text', autoHeight: true },
      { headerName: '매입거래처', field: 'customer_name', width: 110, cellClass: 'left ag-cell130h-br cell-wrap-text', autoHeight: true },
      { headerName: '재고적용', field: 'is_stock', width: 86, cellClass: 'center ag-cell130h-br', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let isStock: any = '';
          let daeguStockQty: any = ( params.data.daegu_stock_qty !== null ) ? params.data.daegu_stock_qty : 0;
          let seoulStockQty: any = ( params.data.seoul_stock_qty !== null ) ? params.data.seoul_stock_qty : 0;

          if ( params.data.is_stock == '0' ) {
            isStock = '<span class="badge badge-secondary f12">적용안함</span>';
          } else {
            isStock = '<span class="badge badge-success f12">재고적용</span>';
          }

          return `${isStock}<br/>대구 : ${daeguStockQty}<br/>서울 : ${seoulStockQty}`;
        }
      },
      { headerName: '안전재고', field: 'safe_stock', width: 80, cellClass: 'center ag-cell130h' },
      // { headerName: '재입고기간', field: 'stock_import_day', width: 90, cellClass: 'center ag-cell130h' },
      { headerName: '판매/상사/협력점/프렌차이즈/구매', field: '', width: 125, cellClass: 'right ag-cell130h-br', cellRenderer: 'agGridHtmlComponent',
        valueFormatter: this.agGridExComponent.currencyFormatter,
        valueGetter(params: any) {
          let consumer_price = getComma(params.data.consumer_price);
          let com_price = getComma(params.data.com_price);
          let b2b_price = getComma(params.data.b2b_price);
          let fra_price = getComma(params.data.fra_price);
          let buy_price = getComma(params.data.buy_price);
          return consumer_price + '<br />' + com_price + '<br />' + b2b_price + '<br />' + fra_price + '<br />' + buy_price;
        }
      },
      { headerName: '마일리지', field: 'mileage', width: 75, cellClass: 'right ag-cell130h', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '등록일', field: 'write_date', width: 105, cellClass: 'center ag-cell130h-br cell-wrap-text', autoHeight: true },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.components = {
      selectCellEditor: this.agGridExComponent.getSelectCellEditor()
    };

    function getComma( num ) {
      var str = String(num);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 그리드 높이 설정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRowHeight = function(params) {
    return 130;
  };

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }
  
  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 위젯 데이터 불러오기
    this.getProductWidget();

    // 검색 카테고리 리스트가져오기
    this.getCategoryList2();

    this.activatedRoute.queryParams.subscribe( async params => {
      if ( typeof params.seq != 'undefined' ) {
        this.params.pageNo = ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? '1' : params.pageNo;
        this.params.pageRow = ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? '20' : params.pageRow;
        this.params.searchField = ( typeof params.searchField == 'undefined' || params.searchField == '' ) ? '' : params.searchField;
        this.params.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
        this.params.status = ( typeof params.status == 'undefined' || params.status == '' ) ? '' : params.status;
        this.params.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? '' : params.sdate;
        this.params.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? '' : params.edate;
        this.params.is_display = ( typeof params.is_display == 'undefined' || params.is_display == '') ? '' : params.is_display;
        this.params.is_mach = ( typeof params.is_mach == 'undefined' || params.is_mach == '') ? '' : params.is_mach;
        this.params.category_code = ( typeof params.category_code == 'undefined' ) ? '' : params.category_code;
        this.params.is_card_installment = ( typeof params.is_card_installment == 'undefined' ) ? '' : params.is_card_installment;
        this.params.approval_yn = ( typeof params.approval_yn == 'undefined' ) ? '1' : params.approval_yn;
        this.params.mileage = ( typeof params.mileage == 'undefined' ) ? '' : params.mileage;
        this.params.safe_stock = ( typeof params.safe_stock == 'undefined' ) ? '' : params.safe_stock;
        this.params.totalCount = ( typeof params.totalCount == 'undefined' ) ? '' : params.totalCount;
      }
 //     this.searchInit();
      // 상품 데이터 불러오기
      this.getProductList();
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : select 전용 카테고리 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCategoryList2() {
    this.categoryService.getCategoryList2().then( response => {
      if (response.ResultCode) {
        this.categoryList = response.data;
      } else {
        this.categoryList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.setHeaderHeight(48);
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field == 'property_seq' ) {
      this.productAdd( $event.data.seq );
    }
    
    if( $event.colDef.field === 'thumbnail_url' ) {
      if( $event.data.status == '0' && $event.data.use_yn == '1' ) {
        const url = this.baseURL + '/product/info?productSeq=' + $event.data.seq;
        window.open("about:blank").location.href = url;
      } else {
        this.toastrService.warning('품절 상태에서는 이동할 수 없습니다.');
      }
    }
  }

  /*******************************************************************************
    설  명 : 상품등록
    입력값 : seq
    리턴값 : 없음
  *******************************************************************************/
  productAdd( seq ) {
    this.params.seq = seq;
    

    this.router.navigate(
      ['/product/list/add'],
      { relativeTo: this.activatedRoute,
        queryParams: this.params,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 위젯 통계 가져오기
    입력값 : seq
    리턴값 : 없음
  *******************************************************************************/
  getProductWidget() {
    this.productService.getProductWidget().then( response => {
      this.statistic = response.data;
    });
  }

  /*******************************************************************************
    설  명 : 상품 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProductList() {
    this.productService.getProductList( this.params ).then( response => {
      if( response.ResultCode ) {
        this.productList = response.data;
        this.params.totalCount = response.total;
      } else {
        this.toastrService.error(response.ResultMessage);
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.params = {
      pageNo: 1,
      pageRow: 20,
      totalCount: 0,
      seq: '',
      searchField: 'product_seq',
      searchText: '',
      status: '',
      sdate: '',
      edate: '',
      is_display: '',
      category_code: '',
      is_card_installment: '',
      mileage: '',
      safe_stock: '',
      approval_yn: '1' , 
      is_mach:''
    };

    this.getProductList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getProductList();
    }
  }

  /*******************************************************************************
    설  명 : 검색 버튼에서 클릭 시 처리
    입력값 : key = this.params 키 값, value = 적용 값
    리턴값 : 없음
  *******************************************************************************/
  searchParams( key, value ) {
    this.params[key] = value;

    this.getProductList();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.params.sdate = this.utilService.getDate( '' );
      obj.close();
    } else {
      this.params.edate = this.utilService.getDate( '' );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 상품 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  deleteProductList() {
    let nodes = this.gridApi.getSelectedRows();

    if( nodes.length < 1 ) {
      this.toastrService.error('삭제할 상품을 선택하시기 바랍니다.', '상품삭제');
      return false;
    }

    if( ! confirm('선택하신 상품을 삭제하시겠습니까?' ) ) {
      return false;
    }

    let data: any = [];
    for( let node of nodes ) {
      data.push( node.seq );
    }

    this.productService.deleteProductList( data ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success(response.ResultMessage, '상품삭제');

        this.getProductWidget();
        this.getProductList();
      } else {
        this.toastrService.error(response.ResultMessage, '상품삭제');
      }
    });

  }

  /*******************************************************************************
    설  명 : 상품 승인 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductApproveSave() {
    let rows = this.gridApi.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error('승인할 상품을 선택하시기 바랍니다.', '상품 승인');
      return false;
    }

    if( ! confirm('선택하신 상품을 승인하시겠습니까?' ) ) {
      return false;
    }

    let data: any = [];
    for( let row of rows ) {
      data.push( row.property_seq );
    }

    this.productService.setProductApproveSave( data ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success(response.ResultMessage, '상품 승인');

        this.getProductList();
      } else {
        this.toastrService.error(response.ResultMessage, '상품 승인');
      }
    });
  }

  /*******************************************************************************
    설  명 : 상품 미승인 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setProductUnApproveSave() {
    let rows = this.gridApi.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error('미승인할 상품을 선택하시기 바랍니다.', '상품 미승인');
      return false;
    }

    if( ! confirm('선택하신 상품을 미승인하시겠습니까?' ) ) {
      return false;
    }

    let data: any = [];
    for( let row of rows ) {
      data.push( row.property_seq );
    }

    this.productService.setProductUnApproveSave( data ).then( response => {
      if( response.ResultCode ) {
        this.toastrService.success(response.ResultMessage, '상품 미승인');

        this.getProductList();
      } else {
        this.toastrService.error(response.ResultMessage, '상품 미승인');
      }
    });
  }
  
  /*******************************************************************************
    설  명 : row 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    onRowClicked($event) {
      this.soldoutList = $event.data.seq;
    }


  /*******************************************************************************
    설  명 : 품절 체크페이지 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  soldOutProduct() {

    const nodes = this.gridApi.getSelectedNodes();

    const data: any = [];
    for(let node of nodes) {
      data.push( node.data.seq );
    }

    this.params.seq = parseInt(data);

    if( data.length < 1) {
      this.toastrService.error('항목을 선택해주세요.');
    } else {

      const url = '/product/list/soldout?seq=' + data;
      window.open("about:blank").location.href = url;

      // this.router.navigate(
      // ['/product/list/soldout'],
      // {
      //   relativeTo: this.activatedRoute,
      //   queryParams: this.params,
      //   queryParamsHandling: '',
      // });
    }

  }

  /*******************************************************************************
    설  명 : 판매현황 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  salesStatus() {
    const nodes = this.gridApi.getSelectedNodes();

    const data: any = [];
    for(let node of nodes) {
      data.push( node.data.seq );
    }

    this.params.seq = parseInt(data);

    if( data.length < 1) {
      this.toastrService.error('항목을 선택해주세요.');
    } else if( data.length >= 2) {
      this.toastrService.error('항목을 하나만 선택해주세요.');
    } else {

      const url = '/product/salesstatus?seq=' + data;
      window.open("about:blank").location.href = url;

    }

  }

  /*******************************************************************************
    설  명 : 페이지 이동
    입력값 : seq
    리턴값 : 없음
  *******************************************************************************/
  goLink( linkUrl: any ) {
    this.router.navigate(
      [linkUrl],
      { relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    // this.params.pageNo = page;
    this.getProductList();
  }

}
