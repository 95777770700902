/*******************************************************************************
  설  명 : 재고관리 > 출고관리 > 당일출고(당일 출고지시서) 탭
  작성일 : 2020-08-14
  작성자 : 송영석
  접속URL : /stock/out/management

  수정일 : 2021.06.03
  수정자 : 서기정
  수정내용 : 프론트엔드
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/service/util.service';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AuthService } from '@app/service/auth.service';
import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';
import { StockOut } from '@app/service/stockOut.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-stock-out-today',
  templateUrl: './stock-out-today.component.html',
  styleUrls: ['./stock-out-today.component.scss']
})
export class StockOutTodayComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};
  public search: any = {
    // date: '',
    searchText: '',
    isPrint: ''
  };
  public isCheckedAll: boolean = false;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  domLayout: any;
  frameworkComponents = this.stockOrderComponent.frameworkComponents;
  defaultColDef = {...this.stockOrderComponent.defaultColDef};
  rowSelection = this.stockOrderComponent.rowSelection;
  noRowsTemplate = this.stockOrderComponent.noRowsTemplate;
  components = this.stockOrderComponent.components;
  gridData: any;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private stockOrderComponent: StockOrderComponent,
    private stockOut: StockOut,
    private authService: AuthService,
  ) {
    this.columnDefs = [
      {headerName: '고객주문번호', field: 'mng_out_seq', width: 100 },
      {headerName: '사용안함1', field: 'x', width: 10 },
      {headerName: '사용안함2', field: 'x', width: 10 },
      {headerName: '받는분성명', field: 'r_name', width: 100 },
      {headerName: '받는분주소', field: 'r_address', width: 100 },
      {headerName: '사용안함3', field: 'x', width: 10 },
      {headerName: '받는분전화번호', field: 'r_hp', width: 100 },
      {headerName: '품목명', field: 'item', width: 100 },
      {headerName: '사용안함4', field: 'x', width: 10 },
      {headerName: '배송메세지', field: 'r_comment', width: 100 },
      {headerName: '운송장번호', field: 'shipping_number', width: 100 },
    ]
  }

  /*******************************************************************************
    설  명 : 그리드 준비 시 처리
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.buildForm();
    this.getStockOutOrderToday();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      order_cnt : [0],
      detail_cnt : [0],
      stockOutOrderTodayList : [[]]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 당일출고지시서 가져오기
  *******************************************************************************/
  async getStockOutOrderToday(): Promise<any> {
    await this.stockOut.getStockOutOrderToday(this.search).then(response => {
      if(response.ResultCode && response.data.length) {
        this.form.patchValue({
          order_cnt : new Set( response.data.map(e => e['order_seq']) ).size,
          detail_cnt : response.data.length,
          stockOutOrderTodayList : this.cooking(response.data)
        })
      } else {
        this.form.patchValue({
          order_cnt : 0,
          detail_cnt : 0,
          stockOutOrderTodayList : []
        })
      }

      this.isCheckedAll = false;
    });
  }

  /*******************************************************************************
    설  명 : 당일출고지시서 데이터 처리
  *******************************************************************************/
  _TLS = string => parseInt(string || '0').toLocaleString();
  cooking(data) {
    const _A = [];
    data.forEach(e => {
      const _index = _A.findIndex(_e => _e['mng_out_seq'] == e['mng_out_seq']);
      const _detail = {
        product_gbn : e['product_gbn'],
        thumbnail_url : e['thumbnail_url'],
        category_name : e['category_name'],
        product_name : e['product_name'],
        color_name : e['color_name'],
        size_name : e['size_name'],
        display_color_name : e['display_color_name'],
        display_size_name : e['display_size_name'],
        qty : e['qty'],
        daegu_stock : e['daegu_stock'] || 0,
        seoul_stock : e['seoul_stock'] || 0,
        unit_price : e['unit_price'],
        amt : e['amt'],
        total_amt : e['total_amt'],
        discount_amt : e['discount_amt'],
        pay_amt : e['pay_amt'],
        warehouse_name : e['warehouse_name'],
        location_cd : e['location_cd'],
        status : e['status'],
        out_order_qty : e['out_order_qty'],
        is_mach : e['is_mach'],

        mng_out_order_seq : e['mng_out_order_seq'],
        warehouse_seq : e['warehouse_seq'],
        warehouse_location_seq : e['warehouse_location_seq'],
        product_seq : e['product_seq'],
        product_property_seq : e['product_property_seq'],
        mng_out_seq : e['mng_out_seq'],
        order_master_seq : e['order_master_seq'],
        order_detail_seq : e['order_detail_seq']
      };
      _index < 0
      ? _A.push({
        is_print : e['is_print'],
        shipping_number : e['shipping_number'],
        order_seq : e['order_seq'],
        order_date : e['order_date'],
        o_name : e['o_name'],
        pay_amt : e['pay_amt'],
        pay_name : e['pay_name'],
        r_address : e['r_address'],
        r_address_detail : e['r_address_detail'],
        r_comment : e['r_comment'],
        order_amt : e['order_amt'],
        mng_out_seq : e['mng_out_seq'],
        order_master_seq : e['order_master_seq'],
        grade : e['grade'],
        grade_name : e['grade_name'],

        r_name : e['r_name'],
        r_hp : e['r_hp'],
        mng_out_delivery_seq : e['mng_out_delivery_seq'],

        _detail : [_detail]
      })
      : _A[_index]['_detail'].push(_detail)
    });

    this.gridForExcel(_A)
    return _A;
  }

  /*******************************************************************************
    설  명 : 완료처리 버튼 클릭
  *******************************************************************************/
  outComplete(data) {
    if(!confirm('완료처리 할까요?')) return

    this.authService.getLoginInfo.subscribe(e => {
      const temp_data = {
        mem_no : e['mem_no'],
        mng_out_order_seq : data['mng_out_order_seq'],

        warehouse_seq : data['warehouse_seq'] || 0,
        warehouse_location_seq : data['warehouse_location_seq'] || 0,
        product_seq : data['product_seq'],
        product_property_seq : data['product_property_seq'],
        inout_qty : data['qty'],
        unit_amt : data['unit_price'],
        amt : data['total_amt'],
        order_master_seq : data['order_master_seq'],
        order_detail_seq : data['order_detail_seq'],
        mng_out_seq : data['mng_out_seq']

      };
      for(const key in temp_data) if(temp_data[key] === null || temp_data[key] === '') {
        this.toastrService.error(`NULL 에러 : ${key} 없음`);
        return;
      }

      this.stockOut.setOutComplete(temp_data).then(response => {
        response.ResultCode ? this.toastrService.success(response.ResultMessage) : this.toastrService.error(response.ResultMessage);
        this.reset();
      });
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 출고지시취소
  *******************************************************************************/
  outException(data, alert = true) {
    if(alert && !confirm('출고지시를 취소하시겠습니까?')) return

    this.authService.getLoginInfo.subscribe(e => {
      this.stockOut.setOutException({
        mem_no : e['mem_no'],
        mng_out_seq : data['mng_out_seq'],
        order_master_seq : data['order_master_seq'],
        mng_out_order_seq : data['mng_out_order_seq'],
        order_detail_seq : data['order_detail_seq']
      }).then(response => {
        response.ResultCode ? this.toastrService.success(response.ResultMessage) : this.toastrService.error(response.ResultMessage)
        this.softReset()
      });
    }).unsubscribe()
  }

  /*******************************************************************************
    설  명 : 출력 버튼 클릭
  *******************************************************************************/
  printOutOrder() {
    let isCheckedCnt = this.form.value.stockOutOrderTodayList.reduce((cnt, item) => cnt + (item.isChecked === true), 0);

    if( isCheckedCnt < 1 ) {
      this.toastrService.warning('출력할 당일 출고지시서를 선택하시기 바랍니다.');
      return false;
    } else {
      // 배경에 있는 내용이 출력되지 않게 하기 위해서
      $(".app-root").css("display", "none");

      // 출력용 메인 div
      const Maindiv = document.createElement('div')
      Maindiv.classList.add("stock-out-today-print");

      Maindiv.style.cssText = 'width: 100%; height: 100%; padding: 10px; position: absolute; z-index: 999; top: 0; left: 0; background-color: #fff;'
      const item = document.getElementsByTagName('tbody')

      for(let i = 0; i < item.length; i++) {
        const input: any = item[i].children[0].children[0].children[0].children[0].children[0];
        if( input.checked === true ) {
          const div = document.createElement('div')
  
          div.style.cssText = `padding-top: 15px; page-break-after: always; height: ${Math.ceil(item[i].querySelectorAll('tbody tr').length / 10) * 100}%;`
          const MainTable = document.createElement('table')
          MainTable.className = 'mb0 table table-bordered table-small'
          MainTable.style.cssText = 'text-align: center'
          MainTable.appendChild( document.getElementsByTagName('colgroup')[0].cloneNode(true) )
          MainTable.appendChild( document.getElementsByTagName('thead')[0].cloneNode(true) )
          const tbody = document.createElement('tbody')
          tbody.appendChild(item[i].cloneNode(true))
          MainTable.appendChild( tbody )
          div.appendChild(MainTable)
          Maindiv.appendChild(div)
        }
      }
      Maindiv.innerHTML = Maindiv.innerHTML.replace(/<(button)[^>]+>[^<]+<[^>]+>/gi, '')
      Maindiv.innerHTML = Maindiv.innerHTML.replace(/<(input)[^>]+>/gi, '')
      document.body.prepend(Maindiv)
      window.onafterprint = () => {
        // 프린트창 닫기 후 출력여부 저장
        this.setIsPrintSave();

        document.body.removeChild(Maindiv);

        // 배경에 있는 내용이 출력되지 않게 하기 위해서
        $(".app-root").css("display", "block");
      }
      Maindiv.onclick = () => window.print();

      alert('이미지 로딩이 끝난 후에 아무곳이나 클릭하시면 출력이 가능합니다.');
    }
  }

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  reset() {
    this.search = {
      // date: '',
      searchText: '',
      isPrint: ''
    };

    this.ngOnInit();
  }

  async softReset(): Promise<any> {
    this.getStockOutOrderToday()
  }

  /*******************************************************************************
    설  명 : 엑셀다운용 그리드 생성
  *******************************************************************************/
  gridForExcel(data) {
    this.gridData = data.filter(e => !e['shipping_number']).map(e => ({
      mng_out_seq : e['mng_out_seq'],
      r_name : e['r_name'],
      r_address : e['r_address'] + e['r_address_detail'],
      r_hp : e['r_hp'],
      item : e['_detail'][0]['category_name'] || '오토바이용품',
      r_comment : e['r_comment'],
      shipping_number : '',
      x : '-'
    }))
  }
  /*******************************************************************************
    설  명 : 택배송장번호 업로드
  *******************************************************************************/
  fileChanged(e) {
    if(!confirm('송장업로드를 진행할까요?')) return

    let fileReader = new FileReader();
    fileReader.onload = () => {
      const params = [];
      Promise.all([
        String(fileReader.result).split(/\r\n|\n/).forEach((e,i) => {
          if(!i || !e) return
          const _A = e.replace('\\', '').split(',')
          params.push({
            mng_out_seq : _A.shift(),
            delivery_company : '0010',
            delivery_no : _A.pop()
          })
        })]
      ).then(async () => {
        await this.stockOut.setDelivery({params : params}).then(async response => {
          response.ResultCode ? this.toastrService.success(response.ResultMessage) : this.toastrService.error(response.ResultMessage);
          await this.softReset();
        })
      })
    }
    fileReader.readAsText(e.target.files[0]);
  }

  /*******************************************************************************
    설  명 : 택배송장번호 삭제
  *******************************************************************************/
  deleteDelivery(mng_out_delivery_seq, mode = 'single') {
    if(!mng_out_delivery_seq) {
      this.toastrService.error('삭제할 송장번호가 없습니다.')
      return
    }
    if(!confirm('송장번호를 삭제하시겠습니까? \r\n송장번호를 다시 업로드할 수 있는 상태가 됩니다.')) return

    this.stockOut.deleteDelivery({mng_out_delivery_seq : mng_out_delivery_seq}).then(response => {
      response.ResultCode ? this.toastrService.success(response.ResultMessage) : this.toastrService.error(response.ResultMessage);
      if(mode == 'single') this.softReset()
    })
  }

  /*******************************************************************************
    설  명 : 택배송장번호 전체삭제
  *******************************************************************************/
  deleteDeliveryAll() {
    if(!confirm('모든 송장번호를 삭제하시겠습니까? \r\n송장번호를 다시 업로드할 수 있는 상태가 됩니다.')) return

    this.form.value.stockOutOrderTodayList.forEach(e => {
      if(e['mng_out_delivery_seq']) {
        this.stockOut.deleteDelivery({mng_out_delivery_seq : e['mng_out_delivery_seq']}).then(response => {
          response.ResultCode ? this.toastrService.success(response.ResultMessage) : this.toastrService.error(response.ResultMessage)
        })
      }
    })
    this.softReset()
  }

  /*******************************************************************************
    설  명 : 출고지시 프린트 출력 여부 저장
  *******************************************************************************/
  setIsPrintSave() {
    let tmp: any = [];
    let list: any = this.form.value.stockOutOrderTodayList;

    list.forEach(item => {
      if( typeof item.isChecked !== undefined && item.isChecked === true ) tmp.push(item.mng_out_seq);
    });

    this.stockOut.setIsPrintSave( JSON.stringify(tmp) ).then(response => {
      if( response.ResultCode ) {
        // this.toastrService.success(response.ResultMessage);
      } else {
        this.toastrService.error(response.ResultMessage);
      }

      this.softReset();
    });
  }

  /*******************************************************************************
    설  명 : 전체 체크 선택
  *******************************************************************************/
  setChecked($event) {
    this.isCheckedAll = $event.target.checked;

    this.form.value.stockOutOrderTodayList.forEach(item => {
      item.isChecked = this.isCheckedAll;
    });
  }

  /*******************************************************************************
    설  명 : 날짜 선택
  *******************************************************************************/
  selectDate($event) {
    const _2 = number => String(`${0}${number}`).slice(-2)
    this.search.date = [
      $event['year'],
      _2($event['month']),
      _2($event['day'])
    ].join('-')
    this.getStockOutOrderToday()
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getStockOutOrderToday();
    }
  }

  /*******************************************************************************
    설  명 : 출고지시 전체취소
  *******************************************************************************/
  outExceptionAll(item) {
    if(!confirm('현재 출고지시 전체를 취소하시겠습니까?')) return

    item._detail.forEach(sub_item =>
      this.outException({
        mng_out_seq : item['mng_out_seq'],
        order_master_seq : item['order_master_seq'],
        mng_out_order_seq : sub_item['mng_out_order_seq'],
        order_detail_seq : sub_item['order_detail_seq']
      }, false)
    )
  }

}
