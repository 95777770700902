import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  public memberInfo: any;

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 로그인정보 가져오기
  getMemberInfo(program: string): Promise<any> {
    return this.restful.get({
        program: program,
        service: 'member',
        version: '1.0',
        action: 'getMember'
      }, {
      }
    ).then( response => {
      if( response.ResultCode )
        this.memberInfo = response.data[0];
      else
        this.memberInfo = [];

      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 로그인
  login( form: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'login'
      }, form.value
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 로그아웃
  logout(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'logout'
      }, {}
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 위젯 통계 가져오기
  getMemberStatistic(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberStatistic'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원리스트 가져오기
  getMemberList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 휴면회원 리스트 가져오기
  getMemberDormantList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberDormantList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 휴면회원 해제
  setMemberDormantTransform( array: any ): Promise<any> {
    return this.restful.post({
      program: 'admin',
      service: 'member',
      version: '1.0',
      action: 'setMemberDormantTransform'
    }, {
      params: array
    }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 검색용 회원리스트 가져오기
  getMemberListForSearch( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberListForSearch'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원검색 - 컴포넌트용
  getACMemberList( searchText ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getACMemberList'
      }, {
        searchText: searchText
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상사회원 리스트 가져오기
  getMemberShopList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberShopList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상사회원 담당자 리스트 가져오기
  getMemberShopChargeList( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberShopChargeList'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

   // 상사회원 담당자 리스트 가져오기
   getMemberShopConsultationList( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberShopConsultationList'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  } 
  // 회원 정보 가져오기
  getMemberDetail( memNo: number, dormant: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberDetail'
      }, {
        mem_no: memNo,
        dormant: dormant
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원 정보 가져오기(ID)
  getMemberDetailID( id: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberDetailID'
      }, {
        id: id
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원 정보 가져오기
  getMemberDetailName( memberName: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberDetailName'
      }, {
        memberName: memberName
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // SMS/이메일거부 정보 가져오기
  getMemberRefusalDetail( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberRefusalDetail'
      }, {
        refusalSeq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 중복아이디 검사
  checkIdDuplicate( data: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'checkIdDuplicate'
      }, {
        id: data
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원정보 저장
  setMemberSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원정보 삭제
  setMemberDelete( memNo: number ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberDelete'
      }, {
        mem_no: memNo
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원혜택제 리스트 가져오기
  getMemberBenefitList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberBenefitListNew'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 협력점 리스트 가져오기
  getMemberSisList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberSisList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 협력점 매출정보 가져오기
  getMemberSisDetail( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberSisDetail'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 협력점/프랜차이즈 월별 출고 상품목록 가져오기
  getMemberSisOutOrderProductMonth( seq: any, year: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberSisOutOrderProductMonth'
      }, {
        seq: seq,
        year: year
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // SMS/이메일거부 리스트 가져오기
  getMemberRefusalList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberRefusalList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원의 주소록 리스트 가져오기
  getMemberAddressList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberAddressList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원의 주소 상세정보 가져오기
  getMemberAddressDetail( addressSeq: number ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberAddressDetail'
      }, {
        seq: addressSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원 주소정보 저장
  setMemberAddressSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberAddressSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원 주소정보 삭제
  setMemberAddressDelete( addressSeq: number ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberAddressDelete'
      }, {
        seq: addressSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원의 주문내역 리스트 가져오기
  getMemberOrderList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberOrderList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원의 마일리지내역 리스트 가져오기
  getMemberMileageList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberMileageList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원의 바마쿠폰내역 리스트 가져오기
  getMemberCouponList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberCouponList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


  // 회원의 상담내역 리스트 가져오기
  getMemberMemoList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberMemoList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // SMS/이메일거부 정보 저장
  setMemberRefusalSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberRefusalSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // SMS/이메일 수신거부 삭제
  setMemberRefusalDelete( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberRefusalDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 오일협력점으로 전환
  setOilCheckChange( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setOilCheckChange'
      }, {
        params: data
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 일반회원으로 전환
  setMemberNormalChange( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberNormalChange'
      }, {
        params: data
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원 메모 저장
  setMemberMemoSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberMemoSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원 메모 삭제
  setMemberMemoDelete( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberMemoDelete'
      }, {
        params: data
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  getMemberFindInfo( member: string ): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'member',
      version: '1.0',
      action: 'getMemberFindInfo'
    }, {
      mem_no: member
    }
  ).then(this.extractData)
   .catch(this.handleErrorPromise);
  }

  // 회원 혜택 등급 업데이트
  setMemberPurchaseUpdate(): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberPurchaseUpdate'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상사 담당자 저장
  setMemberShopCharge( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberShopCharge'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  getMemberShopChargeInfo( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberShopChargeInfo'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  delMemberShopCharge( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'delMemberShopCharge'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상사 영업상담 저장
  setMemberShopConsultation( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberShopConsultation'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }  

  getMemberShopConsultationInfo( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberShopConsultationInfo'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


  delMemberShopConsultation( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'delMemberShopConsultation'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }  

  // 업체회원 신청 리스트 가져오기
  getMemberTransitionApplicationList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberTransitionApplicationList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 업체회원신청 거절
  setMemberTransitionApplicationRefuse( applicationSeq: number, mem_no: number ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberTransitionApplicationRefuse'
      }, {
        seq: applicationSeq,
        mem_no: mem_no
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 업체회원신청 승인
  setMemberTransitionApplicationApprove( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMemberTransitionApplicationApprove'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 휴면예정리스트
  getMemberDormantScheduleList(): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'member',
      version: '1.0',
      action: 'getMemberDormantScheduleList'
    }, {}
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  changePassword( memNo: number, password ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'changePassword'
      }, {
        mem_no: memNo,
        pw: password
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 사용가능한 마일리지가져오기
  getSumRemainedMileage( search: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getSumRemainedMileage'
      }, search
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 관리자 마일리지 지급
  setManagerMileage( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setManagerMileage'
      }, form.value
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 관리자 쿠폰지급
  setManagerCoupon( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setManagerCoupon'
      }, form.value
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }


  // 마일리지 삭제
  setMileageDelete( array ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setMileageDelete'
      }, {
        params: array
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

   // 쿠폰 삭제
   setCouponDelete( array ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setCouponDelete'
      }, {
        params: array
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  } 

  // 회원 등급 리스트 가져오기
  getMemberGradeList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberGradeList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 동호회리스트 가져오기
  getSocietyList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getSocietyList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 동호회점수이력 가져오기
  getSocietyHistoryList( seq:any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getSocietyHistoryList'
      }, {seq: seq}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


  // 동호회정보 가져오기
  getSocietyInfo( seq:any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getSocietyInfo'
      }, {seq: seq}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }  

    // 동호회회원리스트 가져오기
    getSocietyMemberList( seq:any ): Promise<any> {
      return this.restful.get({
          program: 'admin',
          service: 'member',
          version: '1.0',
          action: 'getSocietyMemberList'
        }, {seq: seq}
      ).then(this.extractData)
       .catch(this.handleErrorPromise);
    }

  // 동호회관리위젯 정보 가져오기
  getSocietyWidget(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getSocietyTotalCount'
      },{}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }   

  // 동호회점수관리 위젯 정보 가져오기
  getSocietyHistoryWidget(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getSocietyHistoryTotalCount'
      },{}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }    

  // 동호회정보 가져오기
  getSocietyHistoryInfo( seq:any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getSocietyHistoryInfo'
      }, {seq: seq}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  } 

  // 동호회 정보 삭제
  delSociety( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setSocietyDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }  

  // 동호회 정보 삭제
  delSocietyMember( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setSocietyMemberDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }    

  // 동호회 회원 승인/ 미승인 처리
  appSocietyMember( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setSocietyMemberApp'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  } 


  // 동호회 정보 저장
  setSocietyInfo( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setSocietyInfo'
      },  form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }  


    // 동호회 이력 정보 저장
    setSocietyHistoryInfo( form: any ): Promise<any> {
      return this.restful.post({
          program: 'admin',
          service: 'member',
          version: '1.0',
          action: 'setSocietyHistoryInfo'
        },  form.value
      ).then(this.extractData)
       .catch(this.handleErrorPromise);
    }  
  
  // 동호회 정보 저장
  setSocietyCounselingMemo( data: any): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setSocietyCounselingMemo'
      },  data
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }  

  // 동호회점수 순위 리스트 가져오기
  getSocietyHisotryRankList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getSocietyHisotryRankList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
    
  // 동호회정보 가져오기
  getSocietyHisotryMonthList( seq:any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getSocietyHisotryMonthList'
      }, {seq: seq}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  } 
  
  //바마쿠폰현황 위젯 정보 가져오기
  getMemberCouponWidget(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberCouponTotalCount'
      },{}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }  

  // 바마쿠폰리스트 가져오기
  getCouponList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getCouponList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 쿠폰별 쿠폰발급 리스트  가져오기
  getCouponMemberList( seq:any  , is_use:any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getCouponMemberList'
      }, {
          seq: seq , 
          is_use : is_use
         }
        
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  } 

    // 동호회 엠블럼 이미지 삭제
    setDeleteUploadFile( seq: any ): Promise<any> {
      return this.restful.post({
          program: 'admin',
          service: 'member',
          version: '1.0',
          action: 'setDeleteUploadFile'
        }, {
          imageSeq: seq
        }
      ).then(this.restful.extractData)
       .catch(this.restful.handleErrorPromise);
    }
  
  


}
