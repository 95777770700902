/*******************************************************************************
  설  명 : 주문관리 - 주문관리
  작성일 : 2020-09-05
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbDateStruct, NgbModalOptions, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { UtilService } from '@app/service/util.service';
import { OrderService } from '@app/service/order.service';
import { CommonService } from '@app/service/common.service';

import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridExComponent } from '@components/ag-grid-excomponent/ag-grid-excomponent';
import { StockOrderComponent } from '@page/stock/stock-order/stock-order.component';

import * as moment from 'moment';
import { config } from '@app/service/config.service';

import { OrderPaymentComponent } from '@app/page/order/order-payment/order-payment.component';
import { OrderCreditReplaceComponent } from '@page/order/order-credit-replace/order-credit-replace.component';
import { MemberInfoComponent } from '@page/member/member-info/member-info.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  /*******************************************************************************
    전역 선언부
  *******************************************************************************/
  @ViewChild('search_text', {static: false}) inputSearchText: ElementRef;

  public search: any = {
    pageNo: 1,
    pageRow: 20,
    seq: '',
    // searchField: 'r_name',
    searchText: '',
    searchField: 'date1',
    searchField2: 'r_name',
    searchText2: '',
    paydate: '',
    shop_gbn: '',
    sdate: this.utilService.getDate(moment().subtract(90, 'day')),
    edate: this.utilService.getDate(''),
    order_status: '',
    searchTerm: this.getDays(90),
    searchType: '',
    onoff_gbn: '',
    pay_method: '',
    topSearchText: '',
    suspense: '',
    totalCount: 0,
    year: '',
    month: '',
    date1: ''
  };

  public searchText: any = '';

  public baseURL = config.baseUrl;

  public TotalAmt = {
    orgOrderAmt: 0,
    payAmt: 0,
    inMileageAmt: 0,
    outMileageAmt: 0
  };

  public statistic: any = {
    searchCount: 0,
    todayPayCount: 0,
    standbyCount: 0,
    refundCount: 0
  }

  public term: any = [
    {title: '전체', value: 'A'},
    {title: '오늘', value: this.getDays(0)},
    {title: '7일', value: this.getDays(7)},
    {title: '15일', value: this.getDays(15)},
    {title: '30일', value: this.getDays(30)},
    {title: '90일', value: this.getDays(90)},
    {title: '150일', value: this.getDays(150)},
    {title: '200일', value: this.getDays(200)},
    {title: '365일', value: this.getDays(365)},
  ];

  public orderList: any = [];
  public orderTypeList: any = [];
  public orderShopList: any = [];
  public payMethodList: any = [];

  public memberInfo: any = {};

  public totalCount: number = 0;

  // 그리드 관련 선언
  public components: any;
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  noRowsTemplate: string;
  isRowSelectable: any;
  rowClassRules: any;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private commonService: CommonService,
    private orderService: OrderService,
    private stockOrderComponent: StockOrderComponent,
    private agGridExComponent: AgGridExComponent
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '', field: '', width: 50,
        headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
      },
      // { headerName: '', field: '', width: 60, cellClass: 'cp center',
      //   valueGetter(params: any) {
      //     if( params.data.product_type == '1000' ) {
      //       return '매입';
      //     } else if( params.data.product_type == '2000' ) {
      //       return '입점';
      //     } else {
      //       return '매+입';
      //     }
      //   }
      // },
      { headerName: 'A/S', field: 'order_status', width: 90, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let className: any = '';
          switch( params.data.order_status ) {
            case '0':
              className = 'badge badge-secondary f12';
              break;
            case '1':
              className = 'badge cbpink f12';
              break;
            case '2':
              className = 'badge cbgreenyellow f12';
              break;
            case '3':
              className = 'badge badge-danger f12';
              break;
            case '4':
              className = 'badge badge-danger f12';
              break;
            case '5':
              className = 'badge badge-danger f12';
              break;
            case '6':
              className = 'badge badge-danger f12';
              break;
            case '7':
              className = 'badge cborange f12';
              break;
            case '8':
              className = 'badge cbblue f12';
              break;
            case '9':
              className = 'badge badge-info f12';
              break;
            case '10':
              className = 'badge badge-danger f12';
              break;
            default:
              className = 'badge badge-light f12';
          }

          return '<span class="' + className + '">' + params.data.order_status_name + '</span>';
        }
      },
      { headerName: '주문번호', field: 'seq', width: 100, cellClass: 'cp center' },
      { headerName: '회원등급', field: 'grade', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          let grade: any = params.data.grade;
          let gradeName: any = params.data.grade_name;
          let className: any = '';

          if( grade === '0001' ) className = 'badge-success';
          else if( grade === '0002' ) className = 'cborange';
          else if( grade === '0004' ) className = 'cbpurple';
          else if( grade === '0007' ) className = 'badge-primary';
          else className = 'badge-secondary';

          return `<span class="badge ${className} f12">${gradeName}</span>`;
        }
      },
      { headerName: '주문자', field: 'o_name', width: 100, cellClass: 'cp center' },
      { headerName: '샵구분', field: 'shop_gbn', width: 200, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          if ( params.data.shop_gbn ) {
            var shop = '<font style="color:' + params.data.shop_color + '">' + params.data.shop_name + '</font>';
          } else {
            var shop = '<font style="color:#0000ff;">&nbsp;</font>';
          }

          if( params.data.store_name ) {
            return `${shop} (${params.data.store_name})`;
          } else {
            return shop;
          }
        },
      },
      { headerName: '입금은행', field: 'bank', width: 120, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          var pcmobile = '';
          var my_card_co = '';

          if ( params.data.order_path == 'M' ) {
            pcmobile = '<font style="color:red;">m</font>';
          }

          if( params.data.shop_gbn == "bikemart" && params.data.saler != "mobile" && ( params.data.card_co == "inicis" || !params.data.card_co ) ) {
            my_card_co = '[i]';
          }

          return `${pcmobile} ${params.data.bank} ${my_card_co}`;
        }
      },
      { headerName: '최초주문', field: 'org_order_amt', width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '현재결제', field: 'pay_amt', width: 100, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '수신자', field: 'r_name', width: 100, cellClass: 'cp center', cellRenderer: 'agGridHtmlComponent',
        valueGetter(params: any) {
          var my_r_name = params.data.r_name;

          if ( params.data.shop_gbn == 'bikemart' && params.data.id != '' ) {
            if( params.data.grade == '0004' && params.data.signdate >= params.data.ali_date ) {
              my_r_name = '협력점';
            }
          }

          if( my_r_name == '상사회원' || my_r_name == '협력점' ) {
            return `<font style="color:red;">${my_r_name}</font>`;
          } else {
            return my_r_name;
          }
        }
      },
      { headerName: '주문일자', field: 'order_date', width: 150, cellClass: 'cp center' },
      { headerName: '입금일자', field: 'pay_date', width: 150, cellClass: 'cp center' },
      { headerName: '적립', field: 'in_mileage', width: 70, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '사용', field: 'out_mileage', width: 70, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      { headerName: '송장번호', field: 'delivery_no', width: 140, cellClass: 'cp left', editable: true, singleClickEdit: true,
        valueGetter(params: any) {
          let deliveryNo = ( params.data.delivery_no ) ? params.data.delivery_no : '';

          return deliveryNo.replace(/-/g, '');
        }
      },
      // { headerName: '쿠폰', field: '', width: 70, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
      // { headerName: '슈퍼', field: '', width: 70, cellClass: 'cp right', valueFormatter: this.agGridExComponent.currencyFormatter },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: false,
      resizable: true
    };

    this.rowSelection = 'multiple';

    // 메시지 표시 선언
    this.noRowsTemplate = '검색된 데이터가 없습니다.';

    this.isRowSelectable = function(rowNode) {
      // return rowNode.data ? ( rowNode.data.order_status != '9' ) : false;
      return rowNode.data ? true : false;
    };

    this.rowClassRules = {
      'status1': function (params) {
        return params.data.order_status == '1';
      },
      'status2': function (params) {
        return params.data.order_status == '2';
      },
      'status3': function (params) {
        return params.data.order_status == '3' || params.data.order_status == '4' || params.data.order_status == '5' || params.data.order_status == '6' || params.data.order_status == '10';
      },
      'status4': function (params) {
        return params.data.order_status == '7';
      },
      'status8': function (params) {
        return params.data.order_status == '8' || params.data.order_status == '9';
      },
      'gridBorderBottom': function (params) {
        return params.data.order_status != null;
      }
    };
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    // 공통코드 리스트 가져오기
    this.getCommonList();

    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
    }).unsubscribe();

    this.activatedRoute.queryParams.subscribe( async params => {
      if ( typeof params.seq != 'undefined' ) {
        this.search.pageNo = ( typeof params.pageNo == 'undefined' || params.pageNo == '' ) ? 1 : params.pageNo;
        this.search.pageRow = ( typeof params.pageRow == 'undefined' || params.pageRow == '' ) ? 10 : params.pageRow;
        // this.search.searchField = ( typeof params.searchField == 'undefined' || params.searchField == '' ) ? 'r_name' : params.searchField;
        this.searchText = ( typeof params.searchText == 'undefined' || params.searchText == '' ) ? '' : params.searchText;
        this.search.searchField2 = ( typeof params.searchField2 == 'undefined' || params.searchField2 == '' ) ? 'r_name' : params.searchField2;
        this.search.searchText2 = ( typeof params.searchText2 == 'undefined' || params.searchText2 == '' ) ? '' : params.searchText2;
        this.search.shop_gbn = ( typeof params.shop_gbn == 'undefined' || params.shop_gbn == '' ) ? '' : params.shop_gbn;
        this.search.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? '' : params.sdate;
        this.search.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? '' : params.edate;
        this.search.paydate = ( typeof params.paydate == 'undefined' || params.paydate == '' ) ? '' : params.paydate;
        this.search.order_status = ( typeof params.order_status == 'undefined' || params.order_status == '') ? '' : params.order_status;
        this.search.searchTerm = ( typeof params.searchTerm == 'undefined' ) ? '' : params.searchTerm;
        this.search.searchType = ( typeof params.searchType == 'undefined' ) ? '' : params.searchType;
        this.search.onoff_gbn = ( typeof params.onoff_gbn == 'undefined' ) ? '' : params.onoff_gbn;
        this.search.pay_method = ( typeof params.pay_method == 'undefined' ) ? '' : params.pay_method;
        this.search.suspense = ( typeof params.suspense == 'undefined' ) ? '' : params.suspense;
        this.search.totalCount = ( typeof params.totalCount == 'undefined' ) ? '' : params.totalCount;

        if( this.search.sdate != '' ) this.search.sdate = this.utilService.getDate(this.search.sdate);
        if( this.search.edate != '' ) this.search.edate = this.utilService.getDate(this.search.edate);

        if( this.search.paydate != '' ) this.search.paydate = this.utilService.getDate(this.search.paydate);
      }
      // 헤더 검색
      this.search.topSearchText = ( typeof params.topSearchText == 'undefined' ) ? '' : params.topSearchText;

      if( this.search.pageNo == 1 ) this.getOrderList();
    }).unsubscribe();
  }

  /*******************************************************************************
    설  명 : 공통코드 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    // 주문상태
    this.commonService.getCommonListCode('ORS').then( response => {
      if ( response.ResultCode ) {
        this.orderTypeList = response.data;
      } else {
        this.orderTypeList = [];
      }
    });

    // SHOP
    this.commonService.getCommonListCode('SHP').then( response => {
      if ( response.ResultCode ) {
        this.orderShopList = response.data;
      } else {
        this.orderShopList = [];
      }
    });

    // 결제수단
    this.commonService.getCommonListCode('PMD').then( response => {
      if ( response.ResultCode ) {
        this.payMethodList = response.data;
      } else {
        this.payMethodList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderList() {
    this.search.searchText = this.searchText;

    this.orderService.getOrderList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.orderList = response.data;
        this.statistic = response.statistic;
        this.search.totalCount = response.statistic.searchCount;
        this.TotalAmt.payAmt = response.statistic.payAmt;
        this.TotalAmt.orgOrderAmt = response.statistic.orgOrderAmt;
        this.TotalAmt.inMileageAmt = response.statistic.inMileageAmt;
        this.TotalAmt.outMileageAmt = response.statistic.outMileageAmt;

        if( this.memberInfo.id == 'kej5751' && this.searchText !== '' ) this.searchText = '';

        this.inputSearchText.nativeElement.focus();
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문내역 전체엑셀 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setExcelOrderAll() {
    const contents = [`
      <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
        <caption style="font-size:14pt;"><strong>주문내역서</strong></caption>
        <colgroup>
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:100px;" />
          <col style="width:140px;" />
          <col style="width:90px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:140px;" />
          <col style="width:140px;" />
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:140px;" />
        </colgroup>
        <tbody>
        <tr align="center" bgColor="#f5f7f7">
          <td>A/S</td>
          <td>주문번호</td>
          <td>회원/비회원</td>
          <td>주문자</td>
          <td>샵구분</td>
          <td>입금은행</td>
          <td>최초주문</td>
          <td>현재결제</td>
          <td>수신자</td>
          <td>주문일자</td>
          <td>입금일자</td>
          <td>적립</td>
          <td>사용</td>
          <td>송장번호</td>
        </tr>
    `];

    let search: any = {
      pageNo: this.search.pageNo,
      pageRow: this.search.pageRow,
      seq: this.search.seq,
      searchText: this.search.searchText,
      searchField: this.search.searchField,
      searchField2: this.search.searchField2,
      searchText2: this.search.searchText2,
      paydate: this.search.paydate,
      shop_gbn: this.search.shop_gbn,
      sdate: this.search.sdate,
      edate: this.search.edate,
      order_status: this.search.order_status,
      searchTerm: this.search.searchTerm,
      searchType: this.search.searchType,
      onoff_gbn: this.search.onoff_gbn,
      pay_method: this.search.pay_method,
      topSearchText: this.search.topSearchText,
      suspense: this.search.suspense,
      totalCount: this.search.totalCount,
      year: this.search.year,
      month: this.search.month,
      date1: this.search.date1
    };

    search.pageRow = 9999;

    this.orderService.getOrderList( search ).then( response => {
      if( response.ResultCode ) {

        let data = response.data;

        data.forEach(element => {
          let color = '';
          if(element.order_status == '1') {
            color = "#ffecfc";
          } else if(element.order_status == '2') {
            color = "#ebffe1";
          } else if(element.order_status == '3' || element.order_status == '4' || element.order_status == '5' || element.order_status == '6' || element.order_status == '10') {
            color = "#ffc7ac";
          } else if(element.order_status == '7') {
            color = "#ffedc8";
          } else if(element.order_status == '8' || element.order_status == '9') {
            color = "#f0f0ff";
          }

          if ( element.shop_gbn ) {
            var shop = '<font style="color:' + element.shop_color + '">' + element.shop_name + '</font>';
          } else {
            var shop = '<font style="color:#0000ff;">&nbsp;</font>';
          }

          if( element.store_name ) {
            var store = `${shop} (${element.store_name})`;
          } else {
            var store = shop;
          }

        contents.push(`
            <tr bgColor="${color}">
              <td style="text-align:center;">${element.order_status_name}</td>
              <td style="text-align:center;">${element.seq}</td>
              <td style="text-align:center;">${element.id ? '회원' : '비회원'}</td>
              <td style="text-align:center;">${element.o_name}</td>
              <td style="text-align:center;">${store}</td>
              <td style="text-align:center;">${element.bank}</td>
              <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.org_order_amt !== null ? element.org_order_amt : 0}</td>
              <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.pay_amt !== null ? element.pay_amt : 0}</td>
              <td style="text-align:center;">${element.r_name}</td>
              <td style="text-align:center;">${element.order_date ? element.order_date : ''}</td>
              <td style="text-align:center;">${element.pay_date ? element.pay_date : ''}</td>
              <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.in_mileage}</td>
              <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.out_mileage ? element.out_mileage : 0}</td>
              <td style="text-align:center; mso-number-format:'\#\,\#\#0';">${element.send_letter}</td>
            </tr>
            </tbody>
          `)
        });
        contents.push(`</table>`);

        let today = new Date();

        const fileName = `bikemart_orderList_${[today.getFullYear(), today.getMonth()+1, today.getDate()].join('')}`;

        this.printExcel(fileName, contents.join(''));

      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문내역 엑셀 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setExcelOrder() {
    const contents = [`
      <table border="1" cellpadding="2" cellspacing="1" bordercolor="#666666">
        <caption style="font-size:14pt;"><strong>주문내역서</strong></caption>
        <colgroup>
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:100px;" />
          <col style="width:140px;" />
          <col style="width:90px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:100px;" />
          <col style="width:140px;" />
          <col style="width:140px;" />
          <col style="width:80px;" />
          <col style="width:80px;" />
          <col style="width:140px;" />
        </colgroup>
        <tbody>
        <tr align="center" bgColor="#f5f7f7">
          <td>A/S</td>
          <td>주문번호</td>
          <td>회원/비회원</td>
          <td>주문자</td>
          <td>샵구분</td>
          <td>입금은행</td>
          <td>최초주문</td>
          <td>현재결제</td>
          <td>수신자</td>
          <td>주문일자</td>
          <td>입금일자</td>
          <td>적립</td>
          <td>사용</td>
          <td>송장번호</td>
        </tr>
    `]

    let data = this.orderList

    data.forEach(element => {
      let color = '';
      if(element.order_status == '1') {
        color = "#ffecfc";
      } else if(element.order_status == '2') {
        color = "#ebffe1";
      } else if(element.order_status == '3' || element.order_status == '4' || element.order_status == '5' || element.order_status == '6' || element.order_status == '10') {
        color = "#ffc7ac";
      } else if(element.order_status == '7') {
        color = "#ffedc8";
      } else if(element.order_status == '8' || element.order_status == '9') {
        color = "#f0f0ff";
      }

      if ( element.shop_gbn ) {
        var shop = '<font style="color:' + element.shop_color + '">' + element.shop_name + '</font>';
      } else {
        var shop = '<font style="color:#0000ff;">&nbsp;</font>';
      }

      if( element.store_name ) {
        var store = `${shop} (${element.store_name})`;
      } else {
        var store = shop;
      }

     contents.push(`
        <tr bgColor="${color}">
          <td style="text-align:center;">${element.order_status_name}</td>
          <td style="text-align:center;">${element.seq}</td>
          <td style="text-align:center;">${element.id ? '회원' : '비회원'}</td>
          <td style="text-align:center;">${element.o_name}</td>
          <td style="text-align:center;">${store}</td>
          <td style="text-align:center;">${element.bank}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.org_order_amt !== null ? element.org_order_amt : 0}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.pay_amt !== null ? element.pay_amt : 0}</td>
          <td style="text-align:center;">${element.r_name}</td>
          <td style="text-align:center;">${element.order_date ? element.order_date : ''}</td>
          <td style="text-align:center;">${element.pay_date ? element.pay_date : ''}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.in_mileage}</td>
          <td style="text-align:right; mso-number-format:'\#\,\#\#0';">${element.out_mileage ? element.out_mileage : 0}</td>
          <td style="text-align:center; mso-number-format:'\#\,\#\#0';">${element.send_letter}</td>
        </tr>
        </tbody>
      `)
    })
    contents.push(`</table>`)

    let today = new Date();

    const fileName = `bikemart_orderList_${[today.getFullYear(), today.getMonth()+1, today.getDate()].join('')}`

    this.printExcel(fileName, contents.join(''))
  }


  /*******************************************************************************
    설  명 : 엑셀 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  printExcel(fileName, contents) {
    const exportContents = `
      <html xmlns:x="urn:schemas-microsoft-com:office:excel">
      <head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
      <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet</x:Name>
      <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions>
      </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml></head>
      <body>${contents}</body></html>
    `
    const blob = new Blob([exportContents], {type: "application/csv;charset=utf-8;"})
    const elem = document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = `${fileName}.xls`
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }
  

  /*******************************************************************************
    설  명 : 우리미결 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSuspenseList() {
    this.search.suspense = '1';
    this.getOrderList();
  }


  /*******************************************************************************
    설  명 : 업체미결 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getComSuspenseList() {
    this.search.suspense = '2';
    this.getOrderList();
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    //this.search.pageNo = page;
    this.getOrderList();
  }

  /*******************************************************************************
    설  명 : row 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field === 'seq' ) {
      this.goDetail($event.data.seq);
    }

    if( $event.colDef.field === 'o_name' ) {
      this.goIdSearch($event.data.id)
    }
  }

  /*******************************************************************************
    설  명 : 주문 취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderCancel() {
    const rows = this.gridApi.getSelectedRows();
    if( rows.length < 1 ) {
      this.toastrService.error('주문 취소할 내역을 선택하시기 바랍니다.');
    } else {
      const params = [];

      let check: boolean;
      rows.forEach(item => {
        if(item.order_status !== '0') check = true;

        params.push(item.seq);
      });

      if( check === true ) {
        this.toastrService.error('주문접수 상태가 아닌 내역이 있습니다.');
      } else {
        if ( confirm('선택하신 주문을 취소하시겠습니까?') ) {
          this.orderService.setOrderCancel( JSON.stringify(params) ).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success(response.ResultMessage);

              this.getOrderList();
            } else {
              this.toastrService.error(response.ResultMessage);
            }
          });
        }
      }
    }
  }

  /*******************************************************************************
    설  명 : 주문 통합
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderCombine() {
    const rows = this.gridApi.getSelectedRows();
    if( rows.length <= 1 ) {
      this.toastrService.error('주문 통합할 내역을 선택하시기 바랍니다.');
    } else {
      const params = [];

      let check: boolean;
      let check_member: boolean;
      let prev_mem_no: any = '';
      rows.forEach(item => {
        if(item.order_status !== '0') check = true;
        if( prev_mem_no != '' && prev_mem_no != item.mem_no ) check_member = true;

        params.push(item.seq);

        prev_mem_no = item.mem_no;
      });

      if( check === true ) {
        this.toastrService.error('주문접수 상태에서만 통합이 가능합니다.');
      } else if( check_member == true ) {
        this.toastrService.error('같은 주문자 내역만 통합이 가능합니다.');
      } else {
        if ( confirm('선택하신 주문내역을 통합하시겠습니까?\r가장 최근 주문을 기준으로 통합됩니다.') ) {
          this.orderService.setOrderCombine( JSON.stringify(params) ).then( response => {
            if ( response.ResultCode ) {
              this.toastrService.success(response.ResultMessage);

              this.getOrderList();
            } else {
              this.toastrService.error(response.ResultMessage);
            }
          });
        }
      }
    }
  }
  
  /*******************************************************************************
    설  명 : 결제 확인 등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  orderPayment() {
    const rows = this.gridApi.getSelectedRows();
    if( rows.length < 1 ) {
      this.toastrService.error('결제 확인할 내역을 선택하시기 바랍니다.');
    } else if( rows.length > 1 ) {
      this.toastrService.error('결제 확인은 한 건씩 처리할 수 있습니다.');
    } else {
      if( rows[0].order_status !== '0' ) {
        this.toastrService.error('주문접수 상태에만 결제 처리가 가능합니다.');
      } else {
        const modalRef = this.modalService.open(OrderPaymentComponent, options);

        modalRef.componentInstance.order_seq = rows[0].seq;
        modalRef.componentInstance.o_hp = rows[0].o_hp;

        modalRef.result.then((result) => {
          this.getOrderList();
        }, (reason) => {
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 여신 대체 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setOrderPayCreditReplace() {
    const rows = this.gridApi.getSelectedRows();

    if( rows.length < 1 ) {
      this.toastrService.error('외상 대체할 내역을 선택하시기 바랍니다.');
    } else {
      const modalRef = this.modalService.open(OrderCreditReplaceComponent, optionsLG);

      modalRef.componentInstance.rows = rows;

      modalRef.result.then((result) => {
        this.getOrderList();
      }, (reason) => {
      });
    }
  }

  /*******************************************************************************
    설  명 : 회원정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goMemberInfo(params) {
    const modalRef = this.modalService.open(MemberInfoComponent, optionsLG);

    modalRef.componentInstance.memberNo = params.data.mem_no;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 아이디로 검색하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goIdSearch( id: any ) {
    if( !id || id == '' ) {
      this.toastrService.info('비회원이라 조회가 되지 않습니다.');
    } else {
      this.searchText = '';
      this.search.searchText = '';
      this.search.searchField2 = 'id';
      this.search.searchText2 = id;

      this.getOrderList();
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 행 더블클릭 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDoubleClicked($event) {
    this.goDetail( $event.data.seq );
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check ) {
    if( check == 'sdate' ) {
      this.search.sdate = this.utilService.getDate( '' );
      obj.close();
    } else if( check == 'edate')  {
      this.search.edate = this.utilService.getDate( '' );
      obj.close();
    } else if( check == 'paydate') {
      this.search.paydate = this.utilService.getDate( '' );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      pageNo: 1,
      pageRow: 20,
      seq: '',
      // searchField: 'r_name',
      searchText: '',
      searchField: 'date1',
      searchField2: 'r_name',
      searchText2: '',
      shop_gbn: '',
      sdate: this.utilService.getDate(moment().subtract(90, 'day')),
      edate: this.utilService.getDate(''),
      order_status: '',
      searchTerm: this.getDays(90),
      searchType: '',
      onoff_gbn: '',
      pay_method: '',
      topSearchText: '',
      suspense: '',
      totalCount: 0,
      paydate: '',
      year: '',
      month: '',
      date1: ''
    };

    this.searchText = '';

    this.getOrderList();
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getOrderList();
    }
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  getDays(day: number) {
    const todaysDate = moment();

    let toDate;

    if (day === 0) {
      return 0;
    } else {
      toDate = moment().add(day * -1, 'days');
    }

    return toDate.diff(todaysDate, 'days');
  }

  /*******************************************************************************
    설  명 : 날짜 처리
    입력값 : month
    리턴값 : 없음
  *******************************************************************************/
  onSearchClick( val ) {
    this.search.searchTerm = val;

    if ( val === 'A' ) {
      this.search.sdate = '';
      this.search.edate = '';

    } else {
      let dateModel: NgbDateStruct;

      dateModel = {
        year: parseInt( moment().format('YYYY'), 10 ),
        month: parseInt( moment().format('MM'), 10 ),
        day: parseInt( moment().format('DD'), 10 )
      };

      this.search.edate = dateModel;

      dateModel = {
        year: parseInt( moment().add(val, 'day').format('YYYY'), 10 ),
        month: parseInt( moment().add(val, 'day').format('MM'), 10 ),
        day: parseInt( moment().add(val, 'day').format('DD'), 10 )
      };

      this.search.sdate = dateModel;
    }
  }

  /*******************************************************************************
    설  명 : 주문 등록 및 상세
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail( seq: any ) {
    this.search.seq = seq;

    this.search.sdate = this.utilService.getDateStr(this.search.sdate);
    this.search.edate = this.utilService.getDateStr(this.search.edate);

    this.search.paydate = this.utilService.getDateStr(this.search.paydate);

    this.router.navigate(
    ['/order/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.search,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }
}
