<section id="member-list">
  <section class="widget-div">
    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon1">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">검색<span class="cnt">{{totalCount | number}}</span>명</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">전체회원<span class="cnt">{{statistic?.total_count | number}}</span>명</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">일반회원<span class="cnt">{{statistic?.normal_count | number}}</span>명</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon2">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">비회원<span class="cnt">{{statistic?.non_count | number}}</span>명</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon3">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">휴면회원<span class="cnt">{{statistic?.dormant_count | number}}</span>명</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon4">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">이번달가입<span class="cnt">{{statistic?.month_count | number}}</span>명</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="fl widget cp">
      <div class="fl widget-icon widget-icon5">
        <i class="material-icons">view_list</i>
      </div>
      <div class="fr widget-txt">
        <p class="txt1">오늘가입<span class="cnt">{{statistic?.day_count | number}}</span>명</p>
      </div>
      <div class="cb"></div>
    </div>

    <div class="cb"></div>
  </section>

  <div class="">
    <button type="button" title="검색조건 초기화" class="btn btn-default btn-small-icon" (click)="searchInit()">
      <i class="material-icons-outlined">refresh</i>
    </button>
    <button type="button" title="검색" class="btn btn-default btn-small-icon ml5" (click)="getMemberList()">
      <i class="material-icons-outlined">search</i>
    </button>

    <div class="btn-group ml5">
      <button type="button"
        class="btn btn-default btn-small"
        [ngClass]="{active: search.searchWordStart == ''}"
        (click)="search.searchWordStart='';search.searchWordEnd='';getMemberList()"
      >전체</button>
      <button *ngFor="let item of searchWord"
        type="button"
        class="btn btn-default btn-small"
        [ngClass]="{active: search.searchWordStart == item.start}"
        (click)="search.searchWordStart=item.start;search.searchWordEnd=item.end;getMemberList()"
      >{{item.start}}</button>
    </div>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.grade==''}" (click)="search.grade='';getMemberList()">전체</button>
      <button *ngFor="let item of gradeList" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.grade==item.common_code}" (click)="search.grade=item.common_code;getMemberList()">{{item.common_name}}</button>
    </div>

    <div class="btn-group ml5">
      <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.enteredStatus==''}" (click)="search.enteredStatus='';getMemberList()">전체</button>
      <button *ngFor="let item of enteredStatusList" type="button" class="btn btn-default btn-small" [ngClass]="{active:search.enteredStatus==item.common_code}" (click)="search.enteredStatus=item.common_code;getMemberList()">{{item.common_name}}</button>
    </div>
  </div>

  <hr class="db mt5 mb5" />

  <div class="">
    <div class="fl w80p form-inline">
      <select name="pageRow" [(ngModel)]="search.pageRow" class="form-control form-control-small" (change)="getMemberList()">
        <option value="25">25줄</option>
        <option value="100">100줄</option>
        <option value="500">500줄</option>
        <option value="1000">1000줄</option>
        <option value="5000">5000줄</option>
      </select>
      <!--
      <select name="search_keyword" class="form-control  form-control-small ml5">
        <option value="id">아이디(ID)</option>
        <option value="name">이름</option>
        <option value="shopname">상사이름</option>
        <option value="license">사업자번호</option>
        <option value="res_no1">생년월일</option>
        <option value="email">이메일</option>
        <option value="Hphone">휴대폰번호</option>
        <option value="res_no1">연령</option>
        <option value="usertype">사용자등급</option>
        <option value="Haddr">사용자지역</option>
        <option value="access">접속횟수</option>
        <option value="option1">접속경유</option>
        <option value="email_consent">메일거부</option>
        <option value="realname_check">실명상태</option>
        <option value="nickname">필명</option>
      </select>
      //-->
      <input type="text" size="20" class="form-control form-control-small ml5" [(ngModel)]="search.searchText" (keypress)="searchList($event)" placeholder="이름,아이디,연락처,이메일 검색" />

      <input type="text" size="10" class="form-control form-control-small ml5" [(ngModel)]="search.searchText2" (keypress)="searchList($event)" placeholder="사업자번호 검색" />

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.id==''}" (click)="search.id='';getMemberList();">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.id=='1'}" (click)="search.id='1';getMemberList();">회원</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.id=='0'}" (click)="search.id='0';getMemberList();">비회원</button>
      </div>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.gender==''}" (click)="search.gender='';getMemberList();">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.gender=='M'}" (click)="search.gender='M';getMemberList();">남자</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.gender=='F'}" (click)="search.gender='F';getMemberList();">여자</button>
      </div>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.sales==''}" (click)="search.sales='';getMemberList();">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.sales=='1'}" (click)="search.sales='1';getMemberList();">구매회원</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.sales=='0'}" (click)="search.sales='0';getMemberList();">비구매</button>
      </div>

      <l-select2
        style="width:250px;font-size:12px;"
        [data]="userClass"
        [(ngModel)]="search.userclass"
        (ngModelChange)="setUserclassChange($event)"
        [options]="select2Options"
        class="ml5"
      >
      </l-select2>

      <select class="form-control form-control-small ml5" placeholder="시/도검색" [(ngModel)]="search.state" (change)="getMemberList()">
        <option value="">전체</option>
        <option *ngFor="let item of stateList" [value]="item.common_code">{{item.common_name}}</option>
      </select>
      <input type="text" size="20" class="form-control form-control-small ml5" [(ngModel)]="search.lastLogin" (keypress)="searchList($event)" placeholder="~일전 로그인" />

    </div>
    <div class="fr w20p right">
      <btn-ag-grid-save [gridColumnsApi]="gridColumnApi" gridId="23b39abcfd0b4462829a183cdae7d78a" [btnGroup]="1"></btn-ag-grid-save>
      <button type="button" title="회원추가" class="btn btn-primary btn-small ml5" (click)="addMember(0)">회원추가</button>
    </div>
    <div class="cb"></div>
  </div>

  <hr class="db mt5 mb5" />

  <div class="mb5 form-inline">
    <div class="fl w70p">
      <div class="btn-group">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.email_yn==''}" (click)="search.email_yn='';getMemberList();">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.email_yn=='1'}" (click)="search.email_yn='1';getMemberList();">이메일수신</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.email_yn=='0'}" (click)="search.email_yn='0';getMemberList();">이메일거부</button>
      </div>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.sms_yn==''}" (click)="search.sms_yn='';getMemberList();">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.sms_yn=='1'}" (click)="search.sms_yn='1';getMemberList();">SMS수신</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.sms_yn=='0'}" (click)="search.sms_yn='0';getMemberList();">SMS거부</button>
      </div>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.status==''}" (click)="search.status='';getMemberList();">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.status=='1'}" (click)="search.status='1';getMemberList();">정상회원</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.status=='0'}" (click)="search.status='0';getMemberList();">탈퇴회원</button>
      </div>

      <div class="btn-group ml5">
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.dormant==''}" (click)="search.dormant='';getMemberList();">전체</button>
        <button type="button" class="btn btn-default btn-small" [ngClass]="{active:search.dormant=='Y'}" (click)="search.dormant='Y';getMemberList();">휴면예정</button>
      </div>
      <ag-grid-excel [gridApi]="gridApi1" type="DORMANT" selected="false" filename="휴먼예정 회원리스트" title="휴먼예정 회원리스트"></ag-grid-excel>
    </div>

    <div class="fr w30p right">
      <btn-send [type]="'PUSH'" [sendList]="sendList"></btn-send>
      <btn-send [type]="'SMS'" [sendList]="sendList"></btn-send>
      <btn-send [type]="'EMAIL'" [sendList]="sendList"></btn-send>
      <ag-grid-excel [gridApi]="gridApi" selected="true" filename="회원리스트" title="회원리스트"></ag-grid-excel>
      <ag-grid-excel [gridApi]="gridApi" selected="false" filename="회원리스트" title="회원리스트"></ag-grid-excel>
     </div>

    <div class="cb"></div>
  </div>

  <div style="height:calc(100% - 210px)">
    <ag-grid-angular
      #myGrid

      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="memberList"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      [pagination]="false"
      [paginationPageSize]="paginationPageSize"

      (rowDoubleClicked)="onRowDoubleClicked($event)"
      (gridReady)="onGridReady($event)"
      (sortChanged)="onSortChanged($event)"
      (rowSelected) = "onRowSelected($event)"
      >
    </ag-grid-angular>

    <section class="pagination mt10" *ngIf="totalCount">
      <ngb-pagination
        [(page)]="search.pageNo"
        [pageSize]="search.pageRow"
        [collectionSize]="totalCount"
        [maxSize]="20"
        [rotate]="true"
        [boundaryLinks]="true"
        (pageChange)="loadPage($event)"
        >
        <ng-template ngbPaginationFirst>처음</ng-template>
        <ng-template ngbPaginationLast>마지막</ng-template>
        <ng-template ngbPaginationPrevious>이전</ng-template>
        <ng-template ngbPaginationNext>다음</ng-template>
      </ngb-pagination>
    </section>
  </div>

  <div style="height:calc(0px)">
    
    <ag-grid-angular
      #myGrid
      
      style="width: 100%;height:100%;"
      class="ag-theme-balham"

      [columnDefs]="columnDefs"
      [rowData]="dormantList"
      [domLayout]="domLayout"
      [overlayNoRowsTemplate]="noRowsTemplate"
      [frameworkComponents]="frameworkComponents"
      [pagination]="false"
      [paginationPageSize]="paginationPageSize"

      (gridReady)="onGridReady1($event)"
      >
    </ag-grid-angular>
  </div>
  
</section>
